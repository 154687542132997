import * as React from "react";
import {useParams} from "react-router-dom";
import {fromRawPost, Post} from "../Post";

import {useVolcano} from "../Volcano";
import {useConversions} from "../utils/conversions";
import {CircularProgress, Grid} from "@mui/material";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {useFetch} from "../utils/useFetch";
import {ErrorIndicator} from "../components/ErrorPage";
import "../style/styles.css";
import {MediaCarousel} from "../components/MediaCarousel";


export function VolcanoPosts() {
  const volcanoNumber = Number(useParams()["volcano"]);
  const volcano = useVolcano(volcanoNumber);
  const conversions = useConversions();
  const [posts, setPosts] = React.useState<Post[]>(new Array<Post>);

  React.useEffect(() => {
      fetch(`/api/posts/search?volcano_number=${volcanoNumber}`)
          .then((res) => res.json())
          .then((raw: unknown[]) => {
              return Promise.all(raw.map(fromRawPost));
          })
          .then(setPosts);
  }, []);


  const info =
    volcano === undefined
        ? []
        : [
            ["Country",  volcano.country],
            ["Last Eruption", volcano.lastEruptionYear],
            ["Volcano Type", volcano.primaryVolcanoType],
            ["Elevation", volcano.elevation
                ? conversions.m(Number(volcano.elevation))
                : undefined],
            ["Tectonic Setting", volcano.tectonicSetting],
            ["Major Rock Type", volcano.majorRockType],
        ]

  return (
    <main>
        {/* <h1 className="title-bar">{volcano?.name}</h1> */}
        <MediaCarousel posts={posts}/>
        {/* <h1 className="title-bar">Volcano Information</h1> */}
        <div style={{ marginTop: '5vh' }}>
        <Grid container>
          <Grid container item xs={6}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingTop: '0vh',
                paddingBottom: '3vh',
              }}
            >
              {info.map(([label, value]) => (
                <div className="info-text">
                  <div className="info-label">{label}:</div>
                  <div className="info-value">{value}</div>
                </div>
              ))}
            </div>
          </Grid>

          <Grid container item xs={6}>
            <VolcanoMap volcano={volcano} />
          </Grid>
        </Grid>
      </div>
    </main>
  );
}

function VolcanoMap(props: any) {
  const key = useFetch("/api/gvp/mapsApiKey", { map: (v) => v as string });
  const mapContainerStyle = { width: "calc(100% - 2em)", height: "40vh", marginBottom: "2em" };
  const indicatorContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...mapContainerStyle,
  };
  const center = {
      lat: props.volcano?.location.lat,
      lng: props.volcano?.location.long,
  }

  switch (key.step) {
    case "fetching":
      return (
        <div style={indicatorContainerStyle}>
          <CircularProgress />
        </div>
      );
    case "error":
      return (
        <div style={indicatorContainerStyle}>
          <ErrorIndicator
            header="Something went wrong loading the map."
            body=""
          />
        </div>
      );
    case "done":
      return (
        <LoadScript googleMapsApiKey={key.value}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={8}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              mapTypeId: "terrain",
            }}
            clickableIcons={false}
          >
            <Marker
                position={center}
            />
          </GoogleMap>
        </LoadScript>
      );
  }
}
