/*
* DESCRIPTION: This component handles the About page
*/
// Discription added by Kiernan Pol 11-7-2024
// Discription updated by
import * as React from "react";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import PWAInstallAccordion from "../components/PWAInstallAccordion";
import LocationServicesAccordion from "../components/LocationServicesAccordion";
import HowItWorksAccordion from "../components/HowItWorksAccordion";
import { createTheme, Typography, ThemeProvider } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import { Link } from "../components/Link";

export const theme = (theme: Theme) =>
  createTheme({
    ...theme,
    typography: {
      ...theme.typography,
      h3: {
        ...theme.typography.h3,
        fontSize: 35,
      },
      h4: {
        ...theme.typography.h4,
        fontSize: 25,
      },
      body1: {
        ...theme.typography.body1,
        marginBottom: "0.5em",
      },
    },
  });

export default function About() {
  const useScrollToLocation = () => {
    const scrolledRef = React.useRef(false);
    const { hash } = useLocation();
    const hashRef = React.useRef(hash);

    React.useEffect(() => {
      if (hash) {
        if (hashRef.current !== hash) {
          hashRef.current = hash;
          scrolledRef.current = false;
        }

        if (!scrolledRef.current) {
          const id = hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            scrolledRef.current = true;
          }
        }
      }
    });
  };

  useScrollToLocation();
  return (
    <ThemeProvider theme={theme}>
      <main>
        <Box
          sx={{
            m: 2,
            maxWidth: "md",
          }}
        >
          <div>
            <Typography variant="h3">About</Typography>
          </div>

          <div>
            <Typography>
              Did You See It is still early in development and undergoing
              frequent updates. The development team wants to hear about your
              experience. Please let us know what you like, don't like, and any
              issues you encounter by filling out the{" "}
              <Link to="/feedback">feedback form</Link>.
            </Typography>
          </div>
          <br />

          <div>
            <Typography variant="h4" gutterBottom id={"about-did-you-see-it"}>
              About Did You See It
            </Typography>

            <Typography>
              ***NOTICE***  <br></br>
              If you are using an ad blocker like UblockOrigin then you may experience 
              some videos or images not properly loading.
              ***NOTICE***  <br></br>
            </Typography>
            
            <Typography>
              Did You See It is a citizen science application to report volcanic
              activity in real time.
            </Typography>
            <Typography>
              Volcanologists rely heavily on visual observations of eruptions to
              understand and monitor activity. However, of the 150 active
              volcanoes in the world, only a minority has an observatory staffed
              by professional volcanologists. Even then, professionals can very
              rarely achieve 24/7 visual monitoring. Photos collected through
              Did You See It will increase the temporal and spatial resolution
              of observations available to volcano scientists, enabling them to
              more accurately document and interpret eruptions.
            </Typography>
            <Typography>
              Did You See It was conceptualized by Arianna Soldati and developed
              by students at the University of Colorado, Mai Nguyen, Erin Fast, Kiernan Pol​, Abraham Gutierrez,
              Denver Benjamin Spacek, Jack Hurd, November Valentine, and Glenn Bui
              under the instruction of Debra Parcheta.
            </Typography>
          </div>
          <br />

          <div>
            <Typography variant="h4" gutterBottom id={"how-does-it-work"}>
              How does it work
            </Typography>
            <Typography>
              Our mission is to provide an easy process for capturing and
              sharing photos of volcanic activity whether you're witnessing an
              eruption in person or uploading previously taken photos.
            </Typography>
            <HowItWorksAccordion />
          </div>
          <br />

          <div>
            <div>
              <Typography variant="h4" gutterBottom id={"how-to-help"}>
                How to help
              </Typography>
              <Typography>
                Did You See It is entering its first phase of testing. Thank you
                for taking the time to help us create a useful citizen science
                application. During this testing phase, our primary focus is on
                fixing problems and on how to improve the overall experience.
              </Typography>
              <Typography>
                Our three main goals during this phase are:
              </Typography>
              <Typography>
                1. Test usability and accessibility across different platforms
                and devices.
              </Typography>
              <Typography>
                2. Refine the computer vision technology used to detect and blur
                humans in photos.
              </Typography>
              <Typography>
                3. Collect user feedback on the data being collected.
              </Typography>
              <Typography>
                Help us test usability and accessibility by reporting any issues
                you run into while navigating. Tell us know if you feel like you
                don’t know what you are supposed to do next. Feel free to
                suggest improvements.
              </Typography>
              <Typography>
                Help us refine our computer vision by posting photos you have of
                volcanic activity that do and don’t include people.
              </Typography>
              <Typography>
                We appreciate your help and support in making Did You See It a
                success. You can provide feed back through our{" "}
                <Link to="/feedback">feedback form</Link>
                , or by tapping the bug icon from the top of your screen from
                anwhere on the app.
              </Typography>
            </div>
          </div>
          <br />
          <div>
            <Typography variant="h4" gutterBottom id={"install"}>
              Install as an application
            </Typography>
            <PWAInstallAccordion />
          </div>
          <br />

          <div>
            <Typography variant="h4" gutterBottom id={"how-to-allow-location"}>
              Allow location services
            </Typography>
            <LocationServicesAccordion />
          </div>
          <br />

          <div>
            <Typography variant="h4" gutterBottom id={"ios-metadata"}>
              iOS image metadata
            </Typography>
            <Typography>
              Since iOS 11 (September 2017) iPhones use a default image type of
              HEIC which interferes with the ability to check for the time the
              photo was taken when uploading. If the time the photo was taken
              can't automatically be detected, you will be asked to enter it
              manually. The default image type can be changed in your iPhones
              settings using the following steps.
            </Typography>
            <Typography>1. Open Settings.</Typography>
            <Typography>2. Find and tap 'Camera'.</Typography>
            <Typography>3. Tap 'Formats'.</Typography>
            <Typography>4. Tap 'Most Compatble'.</Typography>
            <Typography>
              Now all new photos you take will be stored as a JPG and the
              information will automatically be detected when uploading.
            </Typography>
          </div>
          <br />

          <div>
            <Typography variant="h4" gutterBottom id={"how-to-allow-location"}>
              Allow location services
            </Typography>
            <LocationServicesAccordion />
          </div>
          <br />

          <Typography>
            At Did You See It, we utilize your location in two key ways to
            enhance your experience. First, we use your location to recommend
            nearby volcanoes on our homepage. Your location is not saved on the
            server. Second, when submitting a photo, we use your location to
            provide scientists with the exact parameters in which the photo was
            taken. Did You See It never stores any additional location
            information.
          </Typography>
        </Box>
      </main>
    </ThemeProvider>
  );
}
