import { CircularProgress } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PostView } from "../components/PostView";
import { usePost } from "../utils/usePost";

export function PostPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const postResult = usePost(Number(id));
  switch (postResult.step) {
    case "fetching":
      return (
        <main>
          <CircularProgress />
        </main>
      );
    case "done":
      return (
        <main>
          <PostView 
            post={postResult.value} 
            onBack={() => navigate(-1)} 
            hideNavigationButtons={false} //Mai Nguyen: Make sure the navigation buttons are on
          />
        </main>
      );
    case "error":
      return (
        <main>
          <div>Something went wrong loading that post.</div>
        </main>
      );
  }
}