/*
* DESCRIPTION by Mai Nguyen: This component handles the volcano selection as well as the creation of observation objects from media 
* that will then be passed to PostObservation component for tagging and eventual upload.
*/


import type { DateTime } from "luxon";
import type { Media } from "../components/MassUpload";
import * as React from "react";
import { useEffect } from "react";
import { VolcanoNameSearch } from "../components/VolcanoNameSearch";
import { defaultObservation, WipObservation } from "../Observation";
import type { Location } from "../Post";
import { PostObservation } from "./PostObservation";
import GeolocationError from "../components/GeolocationError";
import { Card } from "@mui/material";
import { ErrorPage } from "../components/ErrorPage";
import { BackButtonHeader } from "../components/BackButtonHeader";
import MassUpload  from "../components/MassUpload"
import SpreadSheetObservation from "../components/SpreadSheetObservation";

// state that tracks the progression through create post upload process.
type PostState =
  | {
      step: "source";
    }
  | {
      step: "volcano";
      media: Media[];
      tagMethod: string;
      currentPhotoIndex: number; //track the current photo in the process
      observations: WipObservation[];
    }
  | {
      step: "observation";
      media: Media[];
      observations: WipObservation[];
      volcano: number;
      tagMethod: string;
      currentPhotoIndex: number; //track the current photo in the process
    }
  | {
      step: "error";
      error: unknown;
    };

// Primary function
export function CreatePost() {
  const [state, setState] = React.useState<PostState>({ step: "source" });
  const [currentLocation, setCurrentLocation] = React.useState<Location>();
  const [photoLocation, setPhotoLocation] = React.useState<Location>();
  const [datetime] = React.useState<DateTime>();
  const [geolocationWarning, setGeolocationWarning] = React.useState<React.ReactNode | null>(null);
  const [wipObservations, setWipObservations] = React.useState<WipObservation[]>([]);

  // Function to update the observations array
  const updateObservation = (index: number, updatedObservation: WipObservation) => {
    if(state.step === "observation") {
      //make a copy of the observations array
      const updatedObservations = [...state.observations];

      //update the specific observation at the given index
      updatedObservations[index] = updatedObservation;

      //update the state with the modified observations array
      setState({
        ...state,
        observations: updatedObservations,
    });
    }
  };

  //11/18/24 - added the functionality to handle the next photo
  const nextPhoto = () => {
    if (state.step === "observation") {
      const nextIndex = state.currentPhotoIndex + 1;

      if (nextIndex < state.media.length){
        setState({
          ...state,
          currentPhotoIndex: nextIndex,
          step: "volcano", //Navigate back to the volcano selection for the next photo
        });
      } else {
        console.log("All photos processed, ready to upload,");
      }
    }
  };

  //11/12/24 - changed to implement one by one flow
  // 11/20/23 changed back function to accomodate moving the date input to the tagging card.
  function back() {
    switch (state.step) {
      case "source":
        setState({
          step: "error",
          error: new Error("Cannot go back from source step"),
        });
        break;
      case "volcano":
        setState({ step: "source" });
        break;
      case "observation":
        setState({ 
          step: "volcano",
          media: state.media,
          tagMethod: state.tagMethod,
          currentPhotoIndex: state.currentPhotoIndex,
          observations: [],
        });
        break;
      case "error":
        setState({ step: "source" });
        break;
    }
  }

  // Sets the date, time, and location for user media.
  function setMediaDateTimeAndLocation(media : Media[]){
    //Mai Nguyen: iterate through the media files
    for (let i = 0 ; i < media.length ; i++){
      //Mai Nguyen: if media was taken by user using the "Take Photo" button and datetime is missing.
      const currentMedia = media[i]!;
      if (
        currentMedia.datetime === undefined &&
        currentMedia.source === "take" &&
        Math.abs(currentMedia.fileModified.diffNow("minutes").minutes) < 2
      ) {
        currentMedia.datetime = currentMedia.fileModified;
      }

      if(!currentMedia.datetime) {
        console.warn(`Media ${currentMedia.fileName} is missing a datetime`);
        currentMedia.time_unknown = true;
      } else {
        currentMedia.time_unknown = false;
      }

      setPhotoLocation(
        media[i]!.location ??
          checkApplyCurrentLocation(
            media[i]!.datetime,
            currentLocation,
            photoLocation
          )
      );
    }
    console.log("Updated media with datetime and location:", media);
  }

  // Function to set flag if the time of the upload is unknown.
  // 
  function setTimeUnknown(observations : WipObservation[]) {
    for (let i = 0 ; i < observations.length ; i++){
      if (observations[i]!.datetime === undefined){
        observations[i]!.time_unknown = true;
      }
    }
  }

  // hook that gets the current location of the user.
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        const location = { lat: latitude, long: longitude };
        setCurrentLocation(location);
      },
      () =>
        setGeolocationWarning(
          <Card sx={{ m: 2 }}>
            <GeolocationError />
          </Card>
        )
    );
  }, []);

  // applies the current location to the media
  useEffect(() => {
    setPhotoLocation(
      checkApplyCurrentLocation(datetime, currentLocation, photoLocation)
    );
  }, [datetime, currentLocation, photoLocation]);

  // switch statement that controls page display.
  switch (state.step) {
    // Renders the upload screen
    case "source":
      return (
        <>
          {geolocationWarning}
          <MassUpload 
            onSubmit={(mediaUpload , tagMethod) => {
            setMediaDateTimeAndLocation(mediaUpload);
            if(tagMethod === "spSheet"){
              //if "spSheet" method, skip individual upload flow and go to spreadsheetObservation
              setState({
                step: "observation",
                media: mediaUpload,
                observations: wipObservations,
                volcano: 0,
                tagMethod: tagMethod,
                currentPhotoIndex: 0, //start with the first photo
              });
            } else {
              //otherwise proceed with individual flow starting from first photo
              setState({
                step: "volcano",
                media: mediaUpload,
                tagMethod: tagMethod,
                currentPhotoIndex: 0,
                observations: [],
              });
            }
          }}
          />
        </>
      );

    // State that renders the volcano search component, takes user input,
    // and then generates WIPObservations to be passedto PostObservation component
    case "volcano":
      //skip the volcano tagging for spsheet tagging method, may have to change this later for batch upload spreadsheet have to consider how volcano is selected
      if (state.tagMethod === "spSheet") return null;
      const currentMedia = state.media[state.currentPhotoIndex];

      const onVolcanoSubmit = (volcano: number) => {
        const newObservation = defaultObservation(
          currentMedia?.mediaFile!,
          currentMedia?.location,
          volcano,
          currentMedia?.datetime!,
          false,
        );
        //add the new observation to wipObservations
        const updatedObservations = [...wipObservations, newObservation];

        //set time unknown for any undefined datetime
        setTimeUnknown(updatedObservations);

        //update state and wipObservations
        setWipObservations(updatedObservations);

        setState({
          step: "observation",
          media: state.media,
          observations: updatedObservations,
          volcano: volcano,
          tagMethod: state.tagMethod,
          currentPhotoIndex: state.currentPhotoIndex,
        });
      };

      return (
        <main style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
          <BackButtonHeader title = {"Select Volcano"} onBack={back} />
          {geolocationWarning}

          {/* Display the media with the .featured-post-tile style */}
          <div className="featured-post-tile">
            {currentMedia?.mediaFile?.toString().split(':')[1]?.split('/')[0] === 'video' ? (
              <video controls style={{ width: "100%" }}>
                <source src={`${String(currentMedia.mediaFile)}`} type="video/mp4" />
              </video>
            ) : (
              <img
                src={`${String(currentMedia?.mediaFile)}`}
                alt="Selected Media"
                style={{ width: "100%" }}
              />
            )}
          </div>

          {/* Add gap between media and VolcanoNameSearch */}
          <div style={{ marginTop: "20px", width: "100%" }}>
            <VolcanoNameSearch onSubmit={onVolcanoSubmit} />
          </div>
        </main>
      );
    
    // This case navigates the user to the PostObservation or SpreadsheetUplaod screen where user tags their uploads
    // via tagging cards or via spreadsheet.
    case "observation":
      if (state.tagMethod === "spSheet") {
        return (
          <>
            <SpreadSheetObservation 
              media={state.media}
              volcanoID={state.volcano}
              onBack={back}
            />
          </>
        );
      }
      
      return (
        <>
          {geolocationWarning}
          <PostObservation
            observations={
              // [state.observations[state.currentPhotoIndex]].filter(
              //   (observation): observation is WipObservation => !!observation
              // )
              state.observations
            }
            currentPhotoIndex={state.currentPhotoIndex} //pass current index
            totalPhotos={state.media.length}
            onBack={back}
            onUpdateObservation = {(updatedObservation) => 
              updateObservation(state.currentPhotoIndex, updatedObservation)
            }
            onNextPhoto={nextPhoto} //pass nextPhoto as a prop
          />
        </>
      );
      
    case "error":
      return <ErrorPage />;
  }
}


// check if the provided datetime is recent enough to use the provided
// current location as the location for the observation.
// return the new photo location that should be used.
/* NOTE: This function is causing an issue where incorrect location data is being associated with posts and needs to be changed or removed.
*  If users upload media from a different location than where the media was captured AND the location data
*  from the media is not extracted, this function applies the current location to the user's upload. In practice,
*  this is producing incorrect location data that is potentially uploading the location of the uploading user's
*  residence or place of work. 
*/
function checkApplyCurrentLocation(
  datetime: DateTime | undefined,
  currentLocation: Location | undefined,
  photoLocation: Location | undefined
): Location | undefined {
  if (
    photoLocation === undefined &&
    datetime !== undefined &&
    currentLocation !== undefined &&
    Math.abs(datetime.diffNow("minutes").minutes) < 2
  ) {
    return currentLocation;
  } else {
    return photoLocation;
  }
}

