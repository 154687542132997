import * as React from "react";
import {
  Sights,
  SightState,
  SMELL_DISPLAY,
  Smells,
  SOUND_DISPLAY,
  Sounds,
  SIGHT_DISPLAY,
} from "../Observation";
import lavaFountain from "../images/lava_fountain.png";
import aALavaFlow from "../images/a_a_lava_flow.png";
import slabbyPahoehoeFlow from "../images/slabby_pahoehoe_flow.png";
import pelesHair from "../images/peles_hair.png";
import laze from "../images/laze.jpg";
import volcanicPlume from "../images/volcanic_plume.jpg";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "../style/postObservationStyles.css"

function MultiCheckboxInput<Boxes extends Record<string, boolean>>({
  title,
  initBoxes,
  initialComment,
  display,
  onSubmit,
}: {
  title: string;
  initBoxes: Boxes;
  initialComment: string;
  display: Record<keyof Boxes, string>;
  onSubmit: (boxes: Boxes, comment: string) => void;
}) {
  const [boxes, setBoxes] = React.useState(initBoxes);
  const [comment, setComment] = React.useState(initialComment);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 className="popup-header">{title}</h1>
      </div>
      <div 
        style={{
          paddingLeft: '7%', 
          paddingRight: '7%'

        }}
      >
        <FormGroup>
          {Object.entries(boxes)
            .filter(([key, _]) => key !== "skip")
            .map(([key, checked]) => {
              const update = () => {
                setBoxes({
                  ...boxes,
                  [key]: !checked,
                });
              };

              return (
                <FormControlLabel
                  control={<Checkbox checked={checked} onChange={update} />}
                  label={display[key as keyof Sounds]}
                  key={key}
                />
              );
            })}
        </FormGroup>
      </div>
      <TextField
        sx={{
          marginLeft: '5%',
          marginRight: '5%'
        }}
        label="Additional Comments"
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <Button 
          style={{width: '150px'}}
          onClick={() => onSubmit(boxes, comment)}
        >Update</Button>
      </div>
    </div>
  );
}

export function SoundsInput({
  initSounds,
  initialSoundComment,
  onSubmit,
}: {
  initSounds: Sounds;
  initialSoundComment: string;
  buttonLabel: string;
  onSubmit: (sounds: Sounds, comment: string) => void;
}) {
  return (
    <MultiCheckboxInput
      title="Did You Hear It?"
      initBoxes={initSounds}
      initialComment={initialSoundComment}
      display={SOUND_DISPLAY}
      onSubmit={onSubmit}
    />
  );
}

export function SmellsInput({
  initSmells,
  initialSmellComment,
  onSubmit,
}: {
  initSmells: Smells;
  initialSmellComment: string;
  buttonLabel: string;
  onSubmit: (smells: Smells, comment: string) => void;
}) {
  return (
    <MultiCheckboxInput
      title="Did You Smell It?"
      initBoxes={initSmells}
      initialComment={initialSmellComment}
      display={SMELL_DISPLAY}
      onSubmit={onSubmit}
    />
  );
}

const SIGHT_IMAGES: ReadonlyArray<[string, keyof Sights]> = [
  [lavaFountain, "lavaFountain"],
  [aALavaFlow, "aALavaFlow"],
  [slabbyPahoehoeFlow, "slabbyPahoehoeFlow"],
  [pelesHair, "pelesHair"],
  [laze, "laze"],
  [volcanicPlume, "volcanicPlume"],
];

export function SightsInput({
  initialSights,
  onSubmit
}:{
  initialSights: Sights;
  onSubmit: (sights: Sights) => void;
}){
  const [tempSights, setTempSights] = React.useState<Sights>(initialSights);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> , sightKey: any) => {
    setTempSights({
      ...tempSights,
      [sightKey]: (event.target as HTMLInputElement).value as SightState
    })
  };

  return(
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{marginTop: 10, marginBottom: 5}}>Did You See It?</h1>
      <div />
    </div>
    <div className="sight-list-contaier">
      {SIGHT_IMAGES.map(([imageUrl, key]) => (
        <>
          <div className="sight-row" key={key}>
            <div className="sight-row-picture">
              <img 
                style={{
                  width: '100%',
                  minWidth: 0,
                  maxWidth: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  overflow: 'hidden'
                }} 
                src={String(imageUrl)} alt={key}/>
            </div>
            <div className="sight-row-info-container">
              <div className="sight-row-title">
                  <h2>{SIGHT_DISPLAY[key]}</h2>
              </div>
              <div className="sight-row-radio">
                <RadioGroup 
                    row
                    name={String([key])}
                    value={tempSights[key]}
                    onChange={ e => {
                      handleChange(e , key);
                    }}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                    <FormControlLabel value="unsure" control={<Radio />} label="Unsure" />                            
                  </RadioGroup>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          columnGap: "10px",
        }}
      >
        <Button 
          style={{
            width: '150px',
            marginTop: '10px',
            marginBottom: '10px',
          }} onClick={() => onSubmit(tempSights)}>Update</Button>
      </div>
    </div>
  )
}

export function ObservationCommentInput({
  initialComment,
  onSubmit,
}: {
  onSubmit: (comment: string) => void;
  initialComment: string;
}) {
  const [value, setValue] = React.useState(initialComment);

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
    }}>
      <div 
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h1>Additonal Observations</h1>
      </div>
      
      <TextField
        sx={{
          marginLeft: '5%',
          marginRight: '5%'
        }}
        label="Anything else you'd like to share"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={false}
        multiline
        rows={5} 
        //rows makes it a set size and add a scrolling bar if reach limit. Without rows, it expand base on size of text.
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          columnGap: "10px",
        }}
      >
        <Button 
          onClick={() => onSubmit(value)}  
          sx={{ 
            width: '150px',
            minWidth: '20%',
            maxWidth: '30%', 
            marginTop: '10px',
            marginBottom: '10px'
          }}
          >Update</Button>
      </div>
      
    </div>
  );
}


