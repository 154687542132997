/*
DESCRIPTION: SVG file for reject icon - Mai Nguyen 10-31-24
*/
import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material";

export function RejectIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <g transform="scale(.04688)">
        <path
          fill="black"
          d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"
          style={{ fill: "black" }}
        />
        <path
          fill="black"
          d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"
          style={{ fill: "black" }}
        />
      </g>
    </SvgIcon>
  );
}


