/*
* DESCRIPTION: This component handles creating the accept and reject buttons.
*/
// Discriprion added by Kiernan Pol 10-17-2024

import * as React from "react";
import { AcceptIcon } from "../images/AcceptIcon";
import { RejectIcon } from "../images/RejectIcon";
import { IconButton } from "@mui/material";

// This functions is use to create the accept and deny(reject) states
// by creating buttons from image icons (IconButton)
// Discriprion added by Kiernan Pol 10-17-2024

//Updated by Mai Nguyen: fixed the acceptdeny buttons to be more consistent with other icons within the application
export function AcceptDeny({ setState }: { setState: (state: boolean) => void }) {
    const [isAccepted, setIsAccepted] = React.useState<boolean | null>(null); // null indicates no selection

    const handleAccept = () => {
        setIsAccepted(true);
        setState(true);
    };

    const handleReject = () => {
        setIsAccepted(false);
        setState(false);
    };

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
        }}>
            <IconButton
                onClick={handleAccept}
                style={{
                    background: 'none',
                    border: 'none',
                    cursor: isAccepted === false ? 'not-allowed' : 'pointer',
                    marginRight: '2vh'
                }}
            >
                <AcceptIcon
                    style={{
                        color: isAccepted === true ? "darkgreen" : "gray",
                        width: "48px",
                        height: "48px"
                    }}
                />
            </IconButton>

            <IconButton
                onClick={handleReject}
                style={{
                    background: 'none',
                    border: 'none',
                    cursor: isAccepted === true ? 'not-allowed' : 'pointer',
                    marginLeft: '2vh'
                }}
            >
                <RejectIcon
                    style={{
                        color: isAccepted === false ? "darkred" : "gray",
                        width: "48px",
                        height: "48px"
                    }}
                />
            </IconButton>
        </div>
    );
}