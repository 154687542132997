//svg file for stop icon
import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material";

export function StopIcon(props: SvgIconProps) {

  return (
    <SvgIcon {...props}viewBox="0 0 512 512">
        <path
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-width="32"
            d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
        />
        <path
            d="M310.4 336H201.6a25.62 25.62 0 0 1-25.6-25.6V201.6a25.62 25.62 0 0 1 25.6-25.6h108.8a25.62 25.62 0 0 1 25.6 25.6v108.8a25.62 25.62 0 0 1-25.6 25.6"
        />
    </SvgIcon>
  );
}