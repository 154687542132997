/*
* AUTHOR: Mike Kelly
* Popup component that can be resized via css. May be redundant with CustomPopup.tsx component, but
* created this because there were issues overrriding the css for CustomPopup.
*/

import * as React from "react";
import "../style/postObservationStyles.css"
import CancelIcon from '@mui/icons-material/Cancel';

const ResizablePopup = (props:any) => {
  
  return (
    <div className="popup-blur" >
      <div className="popup-frame">
        <span className="popup-close-icon" onClick={props.handleClose}>
          <CancelIcon />
        </span>
        {props.content}
      </div>
    </div>
  );
};
 
export default ResizablePopup;