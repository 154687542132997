import { Box, Button } from "@mui/material";
import type { Post } from "../Post";
import { DateTime } from "luxon";
import {
  Sights,
  SIGHT_DISPLAY,
  Smells,
  SMELL_DISPLAY,
  Sounds,
  SOUND_DISPLAY,
} from "../Observation";
import * as React from "react";
import Container from "@mui/material/Container";
import { BackButtonHeader } from "./BackButtonHeader";
import { CurrentUserContext, Restricted } from "../utils/permissions";
import RemoveObservations from "./RemoveObservations";
import { Link } from "./Link";

export function PostReviewView({
  post,
  overlay,
  onBack,
}: {
  post: Post;
  overlay?: JSX.Element;
  onBack: () => void;
}) {
  const obsSectionStyle = {
    margin: "10px 0",
  };

  const obsListStyle = {
    display: "flex",
    columnGap: "10px",
  };
  const currentUser = React.useContext(CurrentUserContext);
  const [username, setUsername] = React.useState("");
  React.useEffect(() => {
    const userId = post.uploadedBy;
    fetch(`/api/users/public/${userId}/`)
      .then((res) => res.json())
      .then((json) => {
        setUsername(json.username);
      });
  }, [post]);

  const checkedSounds = Object.entries(post.observation.sounds).filter(
    ([_, checked]) => checked
  );

  const checkedSmells = Object.entries(post.observation.smells).filter(
    ([_, checked]) => checked
  );

  const checkedSights = Object.entries(post.observation.sights).filter(
    ([_, answer]) => answer === "yes"
  );

  let temperature; 
  let windSpeed;
  let pressure;
  let tempUnit = "C";
  let speedUnit = "meters/sec";
  let pressureUnit = "hPa";

  if(post.weather){
    temperature = Number(post.weather.temperature) - 273.15;
    windSpeed = Number(post.weather.windSpeed);
    pressure = Number(post.weather.pressure);
    if (currentUser.role !== "public" && !currentUser.metric) {
      temperature = temperature * 1.8 + 32;
      tempUnit = "F";
  
      windSpeed *= 2.236936;
      speedUnit = "mph";
  
      pressure *= 0.02953;
      pressureUnit = "inHg";
    }
  
    temperature = Math.round(temperature * 10) / 10;
    windSpeed = Math.round(windSpeed * 10) / 10;
    pressure = Math.round(pressure * 10) / 10;

  }

  const [removeObservationsOpen, setRemoveObservationsOpen] = React.useState(false);
  const handleRemoveObservationsOpen = () => setRemoveObservationsOpen(true);
  const handleRemoveObservationsClose = () => {
    setRemoveObservationsOpen(false);
    window.location.reload();
  }    

  const handleObservationUpdate = (updatedSights: Sights) => {
    post.observation.sights = updatedSights
  }

  const handleEditObservationsButton = () => {
    handleRemoveObservationsOpen()
  }
  
  return (
    <main
      style={{
        paddingBottom: "20px",
      }}
    >
      <BackButtonHeader
        title={`${
          post.volcano.name
        } - ${post.observation.datetime.toLocaleString()}`}
        onBack={onBack}
      />
      <div
        style={{
          width: "min(800px, 100vw)",
          height: "max-content",
          display: "flex",
          justifyContent: "center",
        }}
      >
          <MediaLoader post={post} />
        
      </div>
      <Container>
        <Box sx={obsSectionStyle}>
          <h3>Uploaded By</h3>
          <Link to={`/profile/${post.uploadedBy}`}>
            {username}
          </Link>
        </Box>
        <Box sx={obsSectionStyle}>
          <h3>Country</h3>
          <div>{post.volcano.country}</div>
        </Box>

        <Box sx={obsSectionStyle}>
          <h3>Date</h3>
          <div>
          { post.observation.time_unknown 
            ? post.observation.datetime.toLocaleString(DateTime.DATE_FULL)
            : post.observation.datetime.toLocaleString(DateTime.DATETIME_FULL)
          }
          </div>
        </Box>
        <Box sx={obsSectionStyle}>
          <h3>Volcano</h3>
          <Link to={`/volcano/${post.volcano.id}`}>
            {post.volcano.name}
          </Link>
        </Box>
        {checkedSounds.length === 0 ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Sounds</h3>
            <Box sx={obsListStyle}>
              {checkedSounds.map(([sound, _]) => {
                return (
                  <span key={sound}>
                    {SOUND_DISPLAY[sound as keyof Sounds]}
                  </span>
                );
              })}
            </Box>
          </Box>
        )}
        {checkedSmells.length === 0 ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Smells</h3>
            <Box sx={obsListStyle}>
              {checkedSmells.map(([smell, _]) => {
                return (
                  <span key={smell}>
                    {SMELL_DISPLAY[smell as keyof Smells]}
                  </span>
                );
              })}
            </Box>
          </Box>
        )}
        {checkedSights.length === 0 ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Sights</h3>
            <Box sx={obsListStyle}>
              {checkedSights.map(([sight, _]) => {
                return (
                  <span key={sight}>
                    {SIGHT_DISPLAY[sight as keyof Sights]}
                  </span>
                );
              })}
            </Box>
          </Box>
        )}
        {post.observation.other.additional === "" ? undefined : (
          <Box sx={obsSectionStyle}>
            <h3>Additional Notes</h3>
            <div>{post.observation.other.additional}</div>
          </Box>
        )}
        {
          post.weather && (
          <Box sx={obsSectionStyle}>
          <h3>Weather</h3>
            <div>
              Temperature: {temperature}&deg; {tempUnit}
              <br />
              Wind Speed: {windSpeed} {speedUnit}
              <br />
              Wind Direction: {post.weather.windDirection}&deg;
              <br />
              Humidity: {post.weather.humidity}%
              <br />
              Pressure: {pressure} {pressureUnit}
              <br />
              Weather's Date:{" "}
              {post.weather.datetime? post.weather.datetime.toLocaleString(DateTime.DATETIME_FULL): ''}
            </div>
          </Box>

          )
        }

        {post.observation.location && (
          <Box sx={obsSectionStyle}>
            <h3>Geolocation</h3>
            <div>
              Latitude: {post.observation.location.lat}
              <br/>
              Longitude: {post.observation.location.long}
              <br/>
            </div>
          </Box>
        )}
        
        {overlay}
        <div>
              <Restricted role="moderator">
                <Button onClick={() => { handleEditObservationsButton() }}>
                  Edit observed sights
                </Button>
              </Restricted>
              <RemoveObservations postid={Number(post.id)} sights={post.observation.sights} open={removeObservationsOpen} handleClose={handleRemoveObservationsClose}  handleUpdate={handleObservationUpdate} />
        </div>
      </Container>
    </main>
  );
}

function MediaLoader({ post }: { post: Post }) {                                                    //attempt to load image if broken load as a video (future: change way the link is created on the database and add video to the end of the link and parse it or make a separate folder for videos)
  const [mediaType, setMediaType] = React.useState<'image' | 'video' | 'unknown'>('unknown');     //might be a hard task if there are a lot of videos since database will need to be changed. Unless videos are placed in a different folder in s3 bucket but database will still need to be updated for all video links

  React.useEffect(() => {
      const testImage = new Image();
      testImage.src = post.observation.photo.toString();
      testImage.onload = () => {
          setMediaType('image');
      };
      testImage.onerror = () => {
          setMediaType('video');
      };
  }, [post.observation.photo]);

  return (
  <>
      {mediaType === 'video' ? (            //display video if media is video Changed to match Garretts image display
                                          <video controls style={{ maxWidth: '100%', maxHeight: '80vh%', display:"flex", margin:"auto",} }> 
                                              <source src={`${String(post.observation.photo)}`} type='video/mp4' />
                                          </video>
                                 ) : 
                  (   <img
                      src={post.observation.photo.toString()}
                      alt={`Photograph of ${post.volcano.name}`}
                      style={{
                          display: "flex",
                          objectFit: "fill",
                          maxHeight: "80vh",
                          maxWidth: "100%",
                          margin: "auto",
                      }}
                  />
                                 )}
  </>
  );
}