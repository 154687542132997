import { Tab, Tabs, /* useMediaQuery */ } from "@mui/material";
import * as React from "react";
import UserSearch from "../components/UserSearch";
import { VolcanoSearch } from "./VolcanoSearch";

// Map Imports
import { CircularProgress, } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useGeolocation } from "../utils/useGeolocation";
import redPin from "../images/redPin.png";
import greyPin from "../images/greyPin.png";
import { useNavigate} from "react-router-dom";
import { useVolcanoesWithPosts, useAllVolcanoes, Volcano } from "../Volcano";
import "@splidejs/react-splide/css";
import "../style/splide.css";
import { useFetch } from "../utils/useFetch";
import { ErrorIndicator } from "../components/ErrorPage";


///////////////////////////////////////////////////////////////////////////////////////////////////////////
// TODO: integrate user search tab Make page css for mobile and desktop 
// (old TODO left from other team need to look into this)

// This code impliments the search feature
// By putting export infront of function this file can be used as an import
// which allows other functions to use these functions
// Comments Updated by Kiernan Pol 10-10-2024, 
export function Search() {
  const [selected, setSelected] = React.useState<0 | 1>(0);
  return (
    <main>
      <Tabs
        centered
        value={selected}
        onChange={(_event, newTab: 0 | 1) => setSelected(newTab)}
      >
        <Tab label="Volcano search" />
        <Tab label="User search" />
      </Tabs>
      <div
       style={{ marginTop: "40px" }}>
        {selected === 0 ? <VolcanoSearch /> : <UserSearch />}
      </div>
      <VolcanoMap />
    </main>
  );
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////////////
// This code creates the map
// By putting export infront of function this file can be used as an import
// which allows other functions to use these functions
// Comments Updated by Kiernan Pol 10-10-2024, 
function VolcanoMap() {
  const volcanoes = useAllVolcanoes();
  const location = useGeolocation();
  const navigate = useNavigate();
  const key = useFetch("/api/gvp/mapsApiKey", { map: (v) => v as string });

  // const mapContainerStyle = { width: "min(400px, 100vw - 50px)", height: "400px"};
  // const indicatorContainerStyle = {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   ...mapContainerStyle,
  // };

  // const center =
  //   location.step === "done"
  //     ? { lat: location.result.lat, lng: location.result.long }
  //     : { lat: -36.840556, lng: 174.74 };

  const mapContainerStyle = { width: "67%", height: "80%" }; // Adjust width to be responsive

  const indicatorContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //..mapContainerStyle,
  };

  // Center coordinates
  const center = location.step === "done"
    ? { lat: location.result.lat, lng: location.result.long }
    : { lat: -36.840556, lng: 174.74 }; // Default center


  let postVolcanoes = useVolcanoesWithPosts().map(Number);

  switch (key.step) {
    case "fetching":
      return (
        <div style={indicatorContainerStyle}>
          <CircularProgress />
        </div>
      );
    case "error":
      return (
        <div style={indicatorContainerStyle}>
          <ErrorIndicator
            header="Something went wrong loading the map."
            body=""
          />
        </div>
      );
    case "done":
      return (
        <div className="map-container">
        <LoadScript googleMapsApiKey={key.value}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            
            center={center}
            zoom={5}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              mapTypeId: "terrain",
            }}
            clickableIcons={false}
          >
            {volcanoes &&
              volcanoes.map((volcano) => (
                <VolcanoMarker
                  key={volcano.id}
                  volcano={volcano}
                  postVolcanoes={postVolcanoes}
                  onClick={() => navigate(`/volcano/${volcano.id}`)}
                />
              ))}
          </GoogleMap>
        </LoadScript>
        </div>
      );
  }
}
export default VolcanoMap;
///////////////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////////////
// This code makes the markers that get put onto the map
// By putting export infront of function this file can be used as an import
// which allows other functions to use these functions
// Comments Updated by Kiernan Pol 10-10-2024, 
export function VolcanoMarker({
  volcano,
  postVolcanoes,
  onClick,
}: {
  volcano: Volcano;
  postVolcanoes: number[];
  onClick?: () => void;
}) {
  const hasPosts = postVolcanoes.includes(volcano.id);
  return (
    <Marker
      onClick={onClick}
      position={{ lat: volcano.location.lat, lng: volcano.location.long }}
      icon={{
        url: hasPosts ? redPin : greyPin,
        scaledSize: new window.google.maps.Size(40, 40),
      }}
    />
  );
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////