import * as React from "react";
import { Avatar } from "@mui/material";
import { fromRawPost, Post } from "../Post";
import { CircularProgress } from "@mui/material";
import { UserMenu } from "../components/UserMenu";
import { useParams } from "react-router-dom";
import { LogoIcon } from "../images/LogoIcon";
import {MediaCarousel} from "../components/MediaCarousel";

function ProfileDetail() {
  const uid = useParams()["uid"]!;
  const [username, setUsername] = React.useState<string>();
  const [posts, setPosts] = React.useState<Post[]>(new Array<Post>);

  React.useEffect(() => {
    fetch(`/api/posts/userposts/${uid}`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
          .then((res) => res.json())
          .then((raw: unknown[]) => {
              return Promise.all(raw.map(fromRawPost));
          })
          .then(setPosts);
  }, []);

  React.useEffect(() => {
    console.log(uid);
    fetch(`/api/users/${uid}/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setUsername(json.username);
      });
  }, [uid]);

  if (username === undefined) {
    return <CircularProgress sx={{ marginTop: "50%" }} />;
  }

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 60px)",
        width: "min(100%, 850px)",
        padding: "0 20px 20px",
        justifyContent: "stretch",
        alignItems: "center",
      }}
    >
      <h1>{username}'s Profile</h1>
      <Avatar sx={{ m: 1, bgcolor: "darkred" }}>
        <LogoIcon />
      </Avatar> 
      <UserMenu username={username} userid={uid} postid={posts[0]?.id} /> {/* New Media Carousel*/}
      <MediaCarousel posts={posts}/>
    </main>
  );
}
export default ProfileDetail;
