/*
* DESCRIPTION: This component handles the file selection and confirmation portion of the upload process.
*/
// Discription added by Kiernan Pol 10-23-2024
// Discription updated by 
import "../style/splide.css";
import "../style/styles.css";
import "../style/massupload.css"
import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { Button, Divider } from '@mui/material';
import { DriveFolderUpload, PhotoCamera} from "@mui/icons-material";
import ExifReader from "exifreader";
import type { Location } from "../Post";
import { DateTime } from "luxon";
import Popup from "./Popup";
import { AcceptDeny } from './AcceptDeny';
import { TrashIcon } from "../images/TrashIcon";
//import { Tag } from "rsuite";



export type Metadata = {
    datetime: DateTime | undefined;
    fileModified: DateTime;
    location: Location | undefined;
    source: "take" | "choose";
}


// Type that facilitates creation of Observation object in CreatePost.
export type Media = {
    id: Number;
    fileName: string | undefined;
    mediaFile: URL | undefined;
    datetime: DateTime | undefined;
    fileModified: DateTime;
    location: Location | undefined;
    source: "take" | "choose";
    time_unknown?: boolean;
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function MassUpload({
  onSubmit,
}: {
  onSubmit: (mediaUpload: Media[] , tagMethod: string) => void
}){
  const [finished, setFinished] = useState(false);
  const [tagMethod, setTagMethod] = useState<string>('');
  const [mediaUpload, setMediaUpload] = React.useState<Media[]>([]);
  const [warningVisible, setWarningVisible] = React.useState(false);

  // Uncomment this line of code to help disable buttons
  const disableButton = true;

  function closePopup() {
      setWarningVisible(false);
      document.getElementById("take-photo-input")!.click();
  }

  // Mai Nguyen: Function to handle delete action
  const handleDelete = (id: Number) => {
    setMediaUpload(mediaUpload.filter((media) => media.id !== id));
  };

  // When the media has been chosen and processed, display them and ask for confirmation.
  if (finished){
    // Display if the user has selected only 1 media file.
    if (mediaUpload.length === 1){
      return (
        <div>
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
              width:'100%',
              height: '5%',
            }}
          >
            <h1>Upload file?</h1>
          </div>

          {/* Mai Nguyen: Grid layout for media upload */}
          <div className="mass-upload-centered-media">
              {mediaUpload.map((media, index) => (
                <div key={index} className="mass-upload-item">
                  {media.mediaFile?.toString().split(':')[1]?.split('/')[0] === 'video' ? (
                    <video controls className="mass-upload-media-contentt">
                      <source src={`${String(media.mediaFile)}`} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={`${String(media.mediaFile)}`}
                      className="mass-upload-media-content"
                      alt={media.fileName}
                      //loading="lazy"
                    />
                  )}
                  <button 
                    onClick={() => handleDelete(media.id)} 
                    className="delete-button"
                    aria-label="Delete media"
                  >
                    <TrashIcon />
                  </button>
                </div>
              ))}
          </div>
          <div>
          <AcceptDeny setState={(accepted) => accepted ? onSubmit(mediaUpload, tagMethod) : setFinished(false)}/>
          </div>
        </div>
        
      );
    } else {
      // return this if the user chose multiple media files.
      return (
        <>
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
              width:'100%',
              height: '5%',
            }}
          >
            <h1>Upload these {mediaUpload.length} files?</h1>
          </div>

          <div className="mass-upload-grid">
            {mediaUpload.map((media, index) => (
                <div key={index} className="mass-upload-item">
                  {media.mediaFile?.toString().split(':')[1]?.split('/')[0] === 'video' ? (
                    <video controls className="mass-upload-media-content">
                      <source src={`${String(media.mediaFile)}`} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={`${String(media.mediaFile)}`}
                      className="mass-upload-media-content"
                      alt={media.fileName}
                      //loading="lazy"
                    />
                  )}
                  <button 
                    onClick={() => handleDelete(media.id)} 
                    className="delete-button"
                    aria-label="Delete media"
                  >
                    <TrashIcon />
                  </button>
                </div>
              ))}
          </div>

          <div>
          <AcceptDeny setState={(accepted) => accepted ? onSubmit(mediaUpload, tagMethod) : setFinished(false)}/>
          </div>
        </>
      );
    }
  }

  // Function that handles extracting the metadata from user's selected photos.  
  const handleUploadEvent = async (target: HTMLInputElement , source: "choose" | "take" , tagMethod: "pick" | "capture" | "spSheet") => {
    let tempUpload: Media[] = [];
    
    // check if the user chose files
      if (target.files){
          //console.log(target.files);
          if (target.files.length !== 0){
            for (let i = 0 ; i < target.files.length ; i++){
                const file = target.files[i]!;
                const fileType = file.type;

                if(fileType.startsWith('video/')){
                  // Check the video duration
                  if (await checkVideoDuration(file)) {
                    alert("Video is longer than 1 minute and cannot be uploaded.  Please edit video before uploading again.");
                    continue; // Skip this video if it's longer than 1 minute
                  }

                  const tempMetaData = await ffmpegMetadata(file,source);
                  const dataUrl = await fileToDataUrl(file);
                  const tempMedia = await createNewMedia(i , target.files[i]?.name, new URL(dataUrl) , tempMetaData);
                  tempUpload.push(tempMedia);

                }else{
                 const tempMetaData = await extractExifData(file, source);
                 const dataUrl = await fileToDataUrl(file);
                 const tempMedia = await createNewMedia(i , target.files[i]?.name, new URL(dataUrl) , tempMetaData);
                 tempUpload.push(tempMedia);
                }
            }
          }

          
          // This code uses removeDuplicateImages function to check for dupilcated images
          // cropped images are treated as if they are a different image
          // Comments added by Kiernan Pol 10-10-2024
          // Comments updated by 
          if(tempUpload.length > 1){
            tempUpload = removeDuplicateImages(tempUpload);

            // Function compareArrayLength(tempUpload, target.files) no longer uses
            if(tempUpload.length < target.files.length){
              alert("Your uploads have duplicated images. All duplicated photos have been removed.")
            }

          }// end of removeDuplicateImages check

          // function call which goes and checks the s3 bucket for any dups
          for(let i = 0; i < tempUpload.length; i++){
            // function call which goes and checks the s3 bucket for any dups
            if(await checkDBForDupsImage(`${String(tempUpload[i]?.fileName)}`) ){
              alert("Some images have been removed because they are already in the database.");
              // Remove only the duplicate image
              tempUpload = tempUpload.filter((_, index) => index !== i);
            }

            // if(await checkDBForDupsVideo(`${String(tempUpload[i]?.fileName)}`) ){
            //   alert("Some images have been removed because they are already in the database.");
            //   // Remove only the duplicate video
            //   tempUpload = tempUpload.filter((_, index) => index !== i);
            // }


          }// end of for loop

          if(tempUpload.length === 0){
            //alert(Uploads have been )
          }

         
          


          if (tempUpload.length > 0){
              console.log('method: ' + tagMethod);
            // //////////////////////////////////////////////////////////////////////////////////////////////
            // // NOTE THIS CODE NEEDs TO BE UNCOMMENTED //
            // //////////////////////////////////////////////////////////////////////////////////////////////
              // //this dynamically checks if the number of files is larger than a predetermined amount
              // //if it is larger than the amount, then it automatically goes to the spSheet tagging method.
              // const assignedTagMethod = tempUpload.length > 5 ? "spSheet" : tagMethod;
              // setTagMethod(assignedTagMethod);
              // setMediaUpload(tempUpload);
              // setFinished(true);
            // //////////////////////////////////////////////////////////////////////////////////////////////


            // // //////////////////////////////////////////////////////////////////////////////////////////////
            // // // NOTE THIS CODE NEEDs TO BE REMOVED //
            // // //////////////////////////////////////////////////////////////////////////////////////////////
            if (tempUpload.length > 5) {
              alert("Oops! It looks like you've exceeded the upload limit. Right now you can upload up to 5 items at a time. Please remove some files and try again. Thank you for understanding!");
            } else {
              const assignedTagMethod = tagMethod;
              setTagMethod(assignedTagMethod);
              setMediaUpload(tempUpload);
              setFinished(true);
            }
            // //////////////////////////////////////////////////////////////////////////////////////////////

          }
      }    
  }

  // Render a group of buttons to the screen what will open the file dialgoue
  // that will allow user to choose which media to upload.
  return(
    <main
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Popup
        title="Safety Warnings"
        open={warningVisible}
        onClose={closePopup}
        content={
          <>
            <p>1. Keep your eyes on the volcano.</p>
            <p>2. Protect yourself from ash, wear safety glasses and a mask.</p>
            <p>3. Avoid low standing areas to escape invisible poisonous gases.</p>
            <p>4. If you feel the lava heat, step back.</p>
            <p>5. Follow the direction of authorities and posted signs.</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Button onClick={closePopup}>Ok</Button>
            </div>
          </>
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <h1>Create a new post</h1>
        <TakePhotoButton
          handleCapture={handleUploadEvent}
          setWarningVisible={setWarningVisible}
        />

        <Box sx={{ padding: "5px 0" }}>
        </Box>

        <TakeVideoButton 
          handleCapture={handleUploadEvent}
          setWarningVisible={setWarningVisible}
        />

        <Box sx={{ padding: "20px 0" }}>
          <Divider>OR</Divider>
        </Box>

        
        <Button 
          component="label" 
          startIcon={<DriveFolderUpload />}
        >
          Choose Photos
            <input type="file" 
                multiple = {true}
                accept="image/*"
                id="inputId"
                onChange={(e) => handleUploadEvent(e.target, "choose" , "pick")}
                style={{ display: "none"}}
            />

        </Button>

        <Box sx={{ padding: "5px 0" }}>
        </Box>

        <Button 
          component="label" 
          startIcon={<DriveFolderUpload />}
        >
          Choose Videos
            <input type="file" 
                multiple = {true}
                accept="video/*"
                id="inputId"
                onChange={(e) => handleUploadEvent(e.target, "choose" , "pick")}
                style={{ display: "none"}}
            />
        </Button>


        {/* <Box sx={{ padding: "20px 0" }}>
          <Divider>OR</Divider>
        </Box> */}

        <Button 
          component="label" 
          startIcon={<DriveFolderUpload />}
          disabled={disableButton} //disable this button for now
          style={{ display: disableButton ? "none" : "inline-flex" }} // Hide button
        >
          Photo Batch Upload
            <input type="file" 
                multiple = {true}
                accept="image/jpeg"
                id="inputId"
                onChange={(e) => handleUploadEvent(e.target, "choose", "spSheet")}
                style={{ display: "none"}}
            />

        </Button>

        <Box sx={{ padding: "5px 0" }}>
        </Box>

        <Button 
          component="label" 
          startIcon={<DriveFolderUpload />}
          disabled={disableButton} //disable this button for now
          style={{ display: disableButton ? "none" : "inline-flex" }} // Hide button
        >
          Video Batch Upload
            <input type="file" 
                multiple = {true}
                accept="video/*"
                id="inputId"
                onChange={(e) => handleUploadEvent(e.target, "choose", "spSheet")}
                style={{ display: "none"}}
            />
        </Button>

      </div>
    </main>
  )
} 
export default MassUpload;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// This function takes a string which is the URL of the uploaded video file
// and check to see if that video file is loner than 1 min
// If > 1 min send error which tells the user that video file is too big
// If < 1 min then no error message will be displayed
// Created and worked on by Kiernan Pol 11-7-2024
// Updated by
async function checkVideoDuration(file: File): Promise<boolean> {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    
    // Load the video data and check duration once loaded
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration > 60); 
    };
    
    video.src = URL.createObjectURL(file);
  });
}
 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// This function will check to see if two images are the same
// Created and worked on by Kiernan Pol 10-10-2024
// Updated by
function areImagesSame(mediaA: Media, mediaB: Media): boolean {
  // Check if both media files are defined and compare their URLs
  if (mediaA.mediaFile && mediaB.mediaFile) {
      return mediaA.mediaFile.href === mediaB.mediaFile.href; // Compare URLs
  }
  return false; // Return false if either mediaFile is undefined
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// This functionn should use the fetch function to go and grab a URL
// If URL is valid then that image already in the database
// If URL is invalid then image is not in database
// Function and comments created by Kiernan Pol
async function checkDBForDupsImage(url: String) {
  url = url.replace(".jpg", "");
  url = url.replace(" - Copy", "");
  try {
    const response = await fetch(`https://didyouseeitphotos.s3.us-east-1.amazonaws.com/media/${url}`);

     if (response.status === 403) {
      //console.warn("403 Forbidden: Access is denied for this URL.");
      return false; // Return false for 403 errors
    }

    return response.ok; // Returns true if successful, false otherwise
  } catch (error) {
    console.error("Fetch error:", error);
    return false; // Return false if there was an error during the fetch
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// This functionn should use the fetch function to go and grab a URL
// If URL is valid then that image already in the database
// If URL is invalid then image is not in database
// Function and comments created by Kiernan Pol
// async function checkDBForDupsVideo(url: String) {
//   url = url.replace(".mp4", "");
//   url = url.replace(" - Copy", "");
//   try {
//     const response = await fetch(`https://didyouseeitphotos.s3.us-east-1.amazonaws.com/video/${url}`);

//      if (response.status === 403) {
//       //console.warn("403 Forbidden: Access is denied for this URL.");
//       return false; // Return false for 403 errors
//     }

//     return response.ok; // Returns true if successful, false otherwise
//   } catch (error) {
//     console.error("Fetch error:", error);
//     return false; // Return false if there was an error during the fetch
//   }
// }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // This function takes a mediaArray and remove all
    // duplicated images from it.
    // Function and comments created by Kiernan Pol
    // Comments updated by
    function removeDuplicateImages(mediaArray: Media[]): Media[] {
      const uniqueMedia: Media[] = [];
    
      for (let i = 0; i < mediaArray.length; i++) {
        const currentMedia = mediaArray[i];
        let foundDuplicate = false;
    
        for (let j = 0; j < uniqueMedia.length; j++) {
          if (areImagesSame(currentMedia as Media, uniqueMedia[j] as Media)) {
            foundDuplicate = true;
            break; // Exit inner loop
          }
        }// end of j loop
    
        // If not found, add to uniqueMedia
        if (!foundDuplicate) {
          uniqueMedia.push(currentMedia as Media);
        }

      }// end of i loop
      // alert(uniqueMedia.length, );
      // alert(uniqueMedia[0]?.fileName);
      // alert(uniqueMedia[1]?.fileName);
      
      return uniqueMedia;
    }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Function that converts a file to a data url so that it can be used in display. 
// This function takes in a file and converts it to a URL as a string.  
// This function uses .readAsDeataURL which takes a file/blob 
// and encodes it in a base64 string (Data: URL). These Data:URLs are 
// used to preview a image without sending it to a server first.
// Updated comments added by Kiernan Pol 10-13-2024, 
function fileToDataUrl(file: File): Promise<string> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
  
      reader.addEventListener("load", () => {
        res(reader.result! as string);
      });
  
      reader.addEventListener("error", rej);
  
      reader.readAsDataURL(file);
    });
  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Function that creates a media object that will be passed to CreatePost 
async function createNewMedia(id: Number, fileName: string | undefined, mediaFile: URL, metaData: Metadata): Promise<Media>{
  return{
    id: id,
    fileName: fileName,
    mediaFile: mediaFile,
    datetime: metaData.datetime,
    fileModified: metaData.fileModified,
    location: metaData.location,
    source: metaData.source
  }
 }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// This function is might not be needed because the size of 
// images changes depending on if there are duplicated
// Updated comments by Kiernan Pol 10-17-2024
//
// // Function to compare array length as a method of error checking.
// async function compareArrayLength(mediaArr: Media[], targetArr: FileList): Promise<boolean>{
//   if (mediaArr.length === targetArr.length){
//     return true;
//   }
//   else{
//     return false;
//   }
// }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Function that extracts the metadata from image files.
// EXIF (Exchangeable Image File Format) data is metadata embedded in image files.
// Things gathered are: gps location, datetime, fileModified, source
// Updated comments added by Kiernan Pol 10-13-2024, 
async function extractExifData(file: File, source: "take" | "choose"): Promise<Metadata> {
    // Using ExifReader.load to get the exif data from file.
    // Including the option 'expanded' to bucket the data to make it easier to understand.
    const { exif, gps } = await ExifReader.load(file, { expanded: true });
    const location = gps?.Latitude === undefined || gps?.Longitude === undefined
    ? undefined
    : { lat: gps.Latitude, long: gps.Longitude };
  
    // Use null-ish coalescing to default to undefined if there is no data.
    const formattedDatetime =
      exif?.DateTime?.value[0] ??
      exif?.DateTimeOriginal?.value[0] ??
      exif?.DateTimeDigitized?.value[0];
  
    let datetime: DateTime | undefined = undefined;
  
    if (formattedDatetime !== undefined) {
      datetime = DateTime.fromFormat(formattedDatetime, "yyyy:MM:dd HH:mm:ss");
    }
  
    return {
      datetime,
      fileModified: DateTime.fromMillis(file.lastModified),
      location,
      source
    };
  }
  {/*
      FFMPEG is discussed in documentation but cros headers need to be set correctly on nginx for servers and django for local development (more details in document)
      
*/}
  async function ffmpegMetadata(file: File, source: "take" | "choose"): Promise<Metadata> {     //This will be used for FFMPEG.wasm in the future for metadata extraction
    //const fileType =file.type;
    return {
      datetime: undefined,
      fileModified: DateTime.fromMillis(file.lastModified),
      location:undefined,
      source
    };
  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Function that handles downloading the image or video that is taken from the app.
// The downloaded image or video will be sent to device photo storage.
const downloadFile = (file: File) => {
  const url = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.download = file.name;
  link.click();
  URL.revokeObjectURL(url);
};

// old download which goes stright to the device storage
// function downloadFile(file: File) {
//   const link = document.createElement("a");
//   link.href = URL.createObjectURL(file);
//   link.download = file.name; // Choose a filename for download
//   link.click();
// }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Function that handles the take photo function when the take photo button is clicked by the user.
// Should open the device's camera. If camera is not present, file dialog will open for user to pick file.
function TakePhotoButton({
    handleCapture,
    setWarningVisible,
  }: {
    handleCapture: (target: HTMLInputElement, source: "take" | "choose", tagMethod: "capture" ) => void;
    setWarningVisible: (visible: boolean) => void;
  }) {

    const [hasWarned, setHasWarned] = React.useState(false);
    

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
          // Call the downloadPhoto function to save the photo
          downloadFile(file);

          // Pass the file input to handleCapture for further handling
          handleCapture(e.target, "take", "capture");
      }
   };

    if (hasWarned) {
      return (
        
        <Button component="label" startIcon={<PhotoCamera />}>
          Take Photo
          <input
            type="file"
            accept="image/*"
            id="take-photo-input"
            capture="environment"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </Button>
      );
    } else {
      return (
        <Button
          startIcon={<PhotoCamera />}
          onClick={() => {
            setHasWarned(true);
            setWarningVisible(true);
          }}
        >
          Take Photo
        </Button>
        
      );
      
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  function TakeVideoButton({
    handleCapture,
    setWarningVisible,
  }: {
    handleCapture: (target: HTMLInputElement, source: "take" | "choose", tagMethod: "capture" ) => void;
    setWarningVisible: (visible: boolean) => void;
  }) {

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
          // Call the downloadPhoto function to save the photo
          downloadFile(file);

          // Pass the file input to handleCapture for further handling
          handleCapture(e.target, "take", "capture");
      }
   };

    const [hasWarned, setHasWarned] = React.useState(false);
    if (hasWarned) {
      return (
        <Button component="label" startIcon={<PhotoCamera />}>
          Take Video
          <input
            type="file"
            multiple = {false}
            accept="video/*"
            id=""
            capture="environment"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </Button>
      );
    } else {
      return (
        <Button
          startIcon={<PhotoCamera />}
          onClick={() => {
            setHasWarned(true);
            setWarningVisible(true);
          }}
        >
          Take Video
        </Button>
      );
    }
  }
 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////