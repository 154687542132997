import { Button } from '@mui/material';
import * as React from 'react';

const Logout = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (localStorage.getItem('token') == null) {
      window.location.replace('/login/');
    } else {
      setLoading(false);
    }
  }, []);

  const handleLogout = (e:any) => {
    e.preventDefault();
    localStorage.clear(); //Clear everything on local storage. Logout on frontend
    window.location.replace('/login/');
  };

  // Mai Nguyen: fixed logout button styling
  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
      {!loading && (
        <div style={{ textAlign: 'center' }}>
          <h1>Do you want to logout?</h1>
          <Button variant="contained" onClick={handleLogout}>Logout</Button>
        </div>
      )}
    </div>
  );
};

export default Logout;