import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import "../style/navbar.css";
import logo from "../images/didyouseeit_logo.png";
import "../style/logo.css";
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem, Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { CurrentUserContext, Restricted, Role } from "../utils/permissions";
import { Link } from "./Link";
import {
  AddAPhoto,
  BugReport,
  CheckCircle,
  Error,
  FilterAlt,
  Help,
  Logout,
  Person,
  PersonAdd,
  Search,
  Warning,
} from "@mui/icons-material";

type MenuLink = { dst: string; label: string; icon: JSX.Element; role?: Role };

export default function Navbar() {
  const currentUser = React.useContext(CurrentUserContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Updated by Mai Nguyen: changed the order of the dropdown menu as well as the buttons that appear on the ribbon
  // Links for the hamburger dropdown menu
  const links: MenuLink[] =
    currentUser.role !== "public"
      ? [
          // If logged in
          {
            dst: "search",
            label: "Search",
            icon: <Search />,
          },
          {
            dst: "feedback",
            label: "Feedback & bug form",
            icon: <BugReport />,
          },
          {
            dst: "safety",
            label: "Safety information",
            icon: <Warning />,
          },
          {
            dst: "post",
            label: "Upload photo",
            icon: <AddAPhoto />,
          },
          {
            dst: "filtersearch",
            label: "Filter posts",
            icon: <FilterAlt />,
          },
          {
            dst: "review",
            label: "Review posts",
            icon: <CheckCircle />,
            role: "volcanologist",
          },
          {
            dst: "reviewreports",
            label: "View reports",
            icon: <Error />,
            role: "moderator",
          },
          {
            dst: "about",
            label: "About",
            icon: <Help />,
          },
          {
            dst: "myprofile",
            label: "My profile",
            icon: <Person />,
          },
          {
            dst: "logout",
            label: "Log out",
            icon: <Logout />,
          },
        ]
      : [
          // Not logged in
          {
            dst: "register",
            label: "Sign up",
            icon: <PersonAdd />,
          },
          {
            dst: "login",
            label: "Log in",
            icon: <Person />,
          },
          {
            dst: "about",
            label: "About",
            icon: <Help />,
          },
        ];
  return (
    <nav className="nav">
      <div style={{ display: "flex", gap: "8px" }}>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            color: "inherit",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {links.map(({ dst, label, icon, role }) => {
            const item = (
              <Link
                to={`/${dst}`}
                onClick={handleClose}
                color="inherit"
                key={dst}
              >
                <MenuItem>
                  <ListItemIcon>{icon}</ListItemIcon>
                  {label}
                </MenuItem>
              </Link>
            );
            if (role === undefined) {
              return item;
            } else {
              return (
                <Restricted role={role} key={dst}>
                  {item}
                </Restricted>
              );
            }
          })}
        </Menu>
        <Link to="/" color="inherit">
          <img className="logo" src={logo} alt="" />
        </Link>
        <Link to="/" color="inherit">
          <span className="site-title">Did You See It?</span>
        </Link>
      </div>

      <ul>
        <LoginNavigation/>
      </ul>
    </nav>
  );
}

function LoginNavigation() {
    // if user is NOT logged in
    if (React.useContext(CurrentUserContext).role === "public") {
        return (
            <ul>
              <Tooltip title="Search for volcanoes and users">
                <IconButton sx={{ color: "inherit" }}>
                  <RouterLink to="/search">
                    <Search />
                  </RouterLink>
                </IconButton>
              </Tooltip>

              <Tooltip title="Safety information">
                <IconButton sx={{ color: "inherit" }}>
                  <RouterLink to="/safety">
                    <Warning />
                  </RouterLink>
                </IconButton>
              </Tooltip>

              <Tooltip title="Log in to your account">
                  <IconButton sx={{color: "inherit"}}>
                      <RouterLink to="/login">
                          <Person />
                      </RouterLink>
                  </IconButton>
              </Tooltip>
            </ul>
        )
    }
    // if user is logged in
    return (
            <ul>
                <Tooltip title="Search for volcanoes and users">
                  <IconButton sx={{ color: "inherit" }}>
                    <RouterLink to="/search">
                      <Search />
                    </RouterLink>
                  </IconButton>
                </Tooltip>
                
                <Tooltip title="Send feedback, report a bug">
                  <IconButton sx={{ color: "inherit" }}>
                    <RouterLink to="/feedback">
                      <BugReport />
                    </RouterLink>
                  </IconButton>
                </Tooltip>
                
                <Tooltip title="Safety information">
                  <IconButton sx={{ color: "inherit" }}>
                    <RouterLink to="/safety">
                      <Warning />
                    </RouterLink>
                  </IconButton>
                </Tooltip>

                <Tooltip title="Create a new post">
                  <IconButton sx={{ color: "inherit" }}>
                    <RouterLink to="/post">
                      <AddAPhoto />
                    </RouterLink>
                  </IconButton>
                </Tooltip>
            </ul>
    )
}