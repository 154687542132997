import * as React from "react";
import {
  FormControl,
  Button,
  Checkbox,
  Box,
  InputLabel,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  MenuItem,
  ListItemText,
  FormGroup,
  Autocomplete,
  TextField,
} from "@mui/material";
import { fromRawPost, Post } from "../Post";
import { Sights, SIGHT_DISPLAY } from "../Observation";

//import "rsuite/dist/rsuite.min.css";
//import { DateRangePicker } from 'rsuite';
//import { DateRangePicker } from "@progress/kendo-react-dateinputs"; //https://www.telerik.com/kendo-react-ui/components/dateinputs/daterangepicker/
import { DateRange, DayPicker } from 'react-day-picker';
import Popup from "../components/Popup";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { format } from 'date-fns';
import {MediaCarousel} from "../components/MediaCarousel";
import { ExportData } from "../components/ExportData";


type Sounds = {
  rumble: boolean;
  roar: boolean;
  shatter: boolean;
  hiss: boolean;
  explosion: boolean;
  burst: boolean;
  silence: boolean;
};

type Smells = {
  rottenEggs: boolean;
  gunPowder: boolean;
  fireworks: boolean;
  campfire: boolean;
  nothing: boolean;
};

const SOUND_DISPLAY: { [key in keyof Sounds]: string } = {
  rumble: "Rumble",
  roar: "Roar",
  shatter: "Shatter",
  hiss: "Hiss",
  explosion: "Explosion",
  burst: "Burst",
  silence: "Silence",
};

const SMELL_DISPLAY: { [key in keyof Smells]: string } = {
  rottenEggs: "Rotten eggs",
  gunPowder: "Gun powder",
  fireworks: "Fireworks",
  campfire: "Campfire",
  nothing: "Nothing",
};

export default function FilterSearch() {
    const [posts, setPosts] = React.useState<Post[]>(new Array<Post>);
    const [filterList, setFilterList] = React.useState<any[]>([]);
    const [country, setCountry] = React.useState<string>("");
    const [listOfCountries, setListOfCountries] = React.useState([]);
    const [openDate, setOpenDate] = React.useState(false);
    const handleDate = () => {
        setOpenDate(!openDate);
    }
    const [range, setRange] = React.useState<DateRange | undefined>();
    let footer = <p>Date Range:<br/> Please pick the first day.</p>;
    if (range?.from) {
        if (!range.to) {
            footer = <p>Date Range:<br/>{format(range.from, 'PPP')}</p>;
        } else if (range.to) {
            footer = (
                <p>Date Range:<br/>
                    {format(range.from, 'PPP')} – {format(range.to, 'PPP')}
                </p>
            );
        }
    }



    React.useEffect(() => {
        fetch("/api/posts/getCountries/", {
            method: "GET",
            headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                setListOfCountries(json);
            });
    }, []);

    const onSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        fetchData(filterList, country, JSON.stringify(range?.from), JSON.stringify(range?.to), setPosts);
    };

    const clearFilter = () => {
        setFilterList([]);
        setCountry("");
        setRange(undefined);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleFilterChange = (event: SelectChangeEvent<typeof filterList>) => {
        const {
            target: {value},
        } = event;
        setFilterList(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <main
            style={{
                display: "flex",
                flexDirection: "column",
                // height: "calc(100vh - 60px)",
                // width: "min(100%, 850px)",
                padding: "0 20px 20px",
                justifyContent: "stretch",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    padding: "20px 0",
                }}
            >
                <Box
                    sx={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "50%",
                    }}
                >
                    <FormGroup>
                        <FormControl sx={{m: 1, width: 300}}>
                            <InputLabel id="demo-multiple-checkbox-label">
                                Select Sound
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                displayEmpty
                                multiple
                                value={filterList}
                                onChange={handleFilterChange}
                                input={<OutlinedInput label="Tag"/>}
                                renderValue={(selected) =>
                                    selected
                                        .map((k) => SOUND_DISPLAY[k as keyof Sounds])
                                        .join("  ")
                                }
                                MenuProps={MenuProps}
                            >
                                {Object.entries(SOUND_DISPLAY).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        <Checkbox checked={filterList.includes(key)}/>
                                        <ListItemText primary={value}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{m: 1, width: 300}}>
                            <InputLabel id="demo-multiple-checkbox-label">
                                Select Smell
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={filterList}
                                onChange={handleFilterChange}
                                input={<OutlinedInput label="Tag"/>}
                                displayEmpty
                                renderValue={(selected) =>
                                    selected
                                        .map((k) => SMELL_DISPLAY[k as keyof Smells])
                                        .join("  ")
                                }
                                MenuProps={MenuProps}
                            >
                                {Object.entries(SMELL_DISPLAY).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        <Checkbox checked={filterList.includes(key)}/>
                                        <ListItemText primary={value}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{m: 1, width: 300}}>
                            <InputLabel id="demo-multiple-checkbox-label">
                                Select Sight
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={filterList}
                                onChange={handleFilterChange}
                                input={<OutlinedInput label="Tag"/>}
                                displayEmpty
                                renderValue={(selected) =>
                                    selected
                                        .map((k) => SIGHT_DISPLAY[k as keyof Sights])
                                        .join("  ")
                                }
                                MenuProps={MenuProps}
                            >
                                {Object.entries(SIGHT_DISPLAY).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        <Checkbox checked={filterList.includes(key)}/>
                                        <ListItemText primary={value}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{m: 1, width: 300}}>
                            <Autocomplete
                                value={country}
                                onChange={(_event: any, newValue: string | any) => {
                                    setCountry(newValue);
                                }}
                                id="controllable-states-demo"
                                options={listOfCountries}
                                sx={{width: 300}}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Country"/>
                                )}
                            />
                        </FormControl>

                        <FormControl sx={{m: 1, width: 300}}>
                            <Button onClick={handleDate}>Select Date Range <DateRangeIcon/> </Button>
                            {footer}
                            <Popup
                                open={openDate}
                                onClose={handleDate}
                                title="Date Range"
                                content={
                                    <>
                                        <DayPicker
                                            id="test"
                                            mode="range"
                                            selected={range}
                                            footer={footer}
                                            onSelect={setRange}
                                        />
                                    </>
                                }
                            />

                        </FormControl>
                    </FormGroup>
                </Box>

                <Box
                    sx={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                    }}
                >
                    <Button onClick={clearFilter}>Clear Filter</Button>
                    <Button onClick={onSubmit}>Submit Filter</Button>
                </Box>
            </div>
            <h1 className="title-bar">{posts[0]?.volcano.name}</h1>
            <MediaCarousel posts={posts}/>
            {posts.length !== 0 && <ExportData postData={posts}/>}
        </main>
    );
}

function fetchData(
    filterList: string[],
    country: string,
    start: string | undefined | any,
    end: string | undefined | any,
    setPostFunc: React.Dispatch<React.SetStateAction<Post[]>>
): void {
    let list = filterList;
    if (filterList.length === 0) {
        list = ["Empty"];
    }
    let tempCountry = country;
    if (country === "") {
        tempCountry = "Empty";
    }

    fetch(`/api/posts/filtersearch/${list}/${tempCountry}/${start}/${end}/`, {
        headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
        },
    })
        .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw res;
            }
        })
        .then(async (rawPosts: Array<unknown>) => {
            return Promise.all(rawPosts.map(fromRawPost));
        })
        .then(setPostFunc);
}

