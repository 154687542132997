/*
DESCRIPTION: SVG file for start icon 
*/
import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material";

export function StartIcon(props: SvgIconProps) {

  return (
    <SvgIcon {...props}viewBox="0 0 512 512">
        <path
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-width="32"
            d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
        />
        <path
            d="m216.32 334.44 114.45-69.14a10.89 10.89 0 0 0 0-18.6l-114.45-69.14a10.78 10.78 0 0 0-16.32 9.31v138.26a10.78 10.78 0 0 0 16.32 9.31"
        />
    </SvgIcon>
  );
}
