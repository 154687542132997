/*
* DESCRIPTION: This component handles the instalation of the  Did You See It PWA.
*/
// Discription added by Kiernan Pol 10-23-2024
// Discription updated by

// Modifications made to formatting and re-done MediaLoader function by Erin Fast

import '../style/styles.css';
import { Box, Button} from "@mui/material";
import type { Post } from "../Post";
import { DateTime } from "luxon";
import {
  Sights,
  SIGHT_DISPLAY,
  Smells,
  SMELL_DISPLAY,
  Sounds,
  SOUND_DISPLAY,
} from "../Observation";
import React from 'react';
import Container from "@mui/material/Container";
import { Link } from "./Link";
import { CurrentUserContext, Restricted } from "../utils/permissions";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";
import RemoveObservations from "./RemoveObservations";
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { CircularProgress } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useFetch } from "../utils/useFetch";
import { ErrorIndicator } from "../components/ErrorPage";

//uncomment to show toggle
//import { Switch, FormControlLabel } from "@mui/material";   // Import needed for toggle - EF
import rightArrow from "../images/arrow-right-circle(new).png";
import leftArrow from "../images/arrow-left-circle(new).png";
import { fromRawPost} from "../Post"; // Adjust the path as necessary

//import cv from "opencv.js";

////////////////////////////////////////////////////////////////////////////////////////////////

export function PostView({
  post,
  overlay,
  hideNavigationButtons = false,
}: {
  post: Post;
  overlay?: JSX.Element;
  onBack: () => void;
  hideNavigationButtons?: boolean; // Mai Nguyen: conditional check for hiding the navigation buttons
}) {
  const obsSectionStyle = {
    margin: "10px 0",
  };

  // State to control toggle - EF
  //comment/uncomment to show toggle again
  //const [toggleState, setToggleState] = React.useState(false);
  //const [showToggle, setShowToggle] = React.useState<boolean>(true);
  const [toggleState] = React.useState(false);
  const [, setShowToggle] = React.useState<boolean>(true);

  const currentUser = React.useContext(CurrentUserContext);
  const [username, setUsername] = React.useState("");
  const navigate = useNavigate();

  // uncomment to show toggle
  const handleMediaTypeDetected = (type: 'image' | 'video') => {
    setShowToggle(type === 'image'); // Show toggle only for images
  };

  // attempt to enable post switching based on volcano name
  const volcanoId = post.volcano?.id;

  //const [posts, setPosts] = React.useState<Post[]>([]);
  const [postIds, setPostIds] = React.useState<number[]>([]); // To store post ids
  const [currentIndex, setCurrentIndex] = React.useState<number>(0); // Current post index
  
  React.useEffect(() => {
    if (!volcanoId) {
      console.error('Volcano ID is undefined');
      return; // Prevent further execution if the volcano ID is undefined
    }

    fetch(`/api/posts/search?volcano_number=${volcanoId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((raw: unknown[]) => {
        return Promise.all(raw.map(fromRawPost));
      })
      .then((processedPosts) => {
        const ids = processedPosts.map(post => Number(post.id));
        setPostIds(ids); // Store the IDs in state
        console.log('Post IDs:', ids);
      })
      .catch(error => {
        console.error('Error fetching posts:', error); // Log any errors
      });
  }, [volcanoId]); // Depend on volcanoId
  
  // Right arrow handling
  const handleNextPost = () => {
    const nextIndex = currentIndex + 1; // Calculate the next index
    if (nextIndex < postIds.length) { // Check if next index is within bounds
      setCurrentIndex(nextIndex); // Update the current index
      navigate(`/post/${postIds[nextIndex]}`); // Navigate to the next post ID
    } else {
      console.log('No next post, navigating to the first post');
      setCurrentIndex(0); // Wrap around to the first post
      navigate(`/post/${postIds[0]}`); // Navigate to the first post ID
    }
  };

  // Left arrow handling
  const handlePrevPost = () => {
    const prevIndex = currentIndex - 1; // Calculate the previous index
    if (prevIndex >= 0) { // Check if previous index is within bounds
      setCurrentIndex(prevIndex); // Update the current index
      navigate(`/post/${postIds[prevIndex]}`); // Navigate to the previous post ID
    } else {
      console.log('No previous post, navigating to the last post');
      setCurrentIndex(postIds.length - 1); // Wrap around to the last post
      navigate(`/post/${postIds[postIds.length - 1]}`); // Navigate to the last post ID
    }
    // navigate(`/post/${Number(post.id)-1}`);
  };

  React.useEffect(() => {
    const userId = post.uploadedBy;
    fetch(`/api/users/public/${userId}/`)
      .then((res) => res.json())
      .then((json) => {
        setUsername(json.username);
      });
  }, [post]);

  const [removeObservationsOpen, setRemoveObservationsOpen] =
    React.useState(false);
  const handleRemoveObservationsOpen = () => setRemoveObservationsOpen(true);
  const handleRemoveObservationsClose = () => setRemoveObservationsOpen(false);

  const handleObservationUpdate = (updatedSights: Sights) => {
    post.observation.sights = updatedSights;
  };

  const handleEditObservationsButton = () => {
    handleRemoveObservationsOpen();
  };

  const [isOpenPost, setIsOpenPost] = React.useState(false);
  const togglePopupPost = () => {
    setIsOpenPost(!isOpenPost);
    navigate(-1);
  };

  const [confirmDeletePost, setConfirmDeletePost] = React.useState(false);
  const toggleConfirmDeletePost = () => {
    setConfirmDeletePost(!confirmDeletePost);
  };
  async function deletePost(pid: any) {
    fetch(`/api/reports/deletePost/${pid}/`, {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.ok) {
        setIsOpenPost(true);
        toggleConfirmDeletePost();
        console.log(res);
      }
    });
  }

  const checkedSounds = Object.entries(post.observation.sounds).filter(
    ([_, checked]) => checked
  );

  const checkedSmells = Object.entries(post.observation.smells).filter(
    ([_, checked]) => checked
  );

  const checkedSights = Object.entries(post.observation.sights).filter(
    ([_, answer]) => answer === "yes"
  );

  const checkPending = post.status;

  let temperature;
  let windSpeed;
  let pressure;
  let tempUnit = "C";
  let speedUnit = "m/s";
  let pressureUnit = "hPa";
  let weatherData: any[] = [];

  if (post.weather) {
    temperature = Number(post.weather.temperature) - 273.15;
    windSpeed = Number(post.weather.windSpeed);
    pressure = Number(post.weather.pressure);
    if (currentUser.role !== "public" && !currentUser.metric) {
      temperature = temperature * 1.8 + 32;
      tempUnit = "F";

      windSpeed *= 2.236936;
      speedUnit = "mph";

      pressure *= 0.02953;
      pressureUnit = "inHg";
    }

    temperature = Math.round(temperature * 10) / 10;
    windSpeed = Math.round(windSpeed * 10) / 10;
    pressure = Math.round(pressure * 10) / 10;
    //2d array for Grid spaccing out data correctly
    weatherData = [
      ['Temperature', `${temperature} °${tempUnit}`],
      ['Wind Speed', `${windSpeed} ${speedUnit}`],
      ['Wind Direction', `${post.weather.windDirection}°`],
      ['Humidity', `${post.weather.humidity}%`],
      ['Pressure', `${pressure} ${pressureUnit}`],
    ];
  }

  function VolcanoMap({ post }: { post: Post }){
    const key = useFetch("/api/gvp/mapsApiKey", { map: (v) => v as string });
    const mapContainerStyle = { 
      width: "30vw", 
      height: "40vh",
    };  
    const indicatorContainerStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...mapContainerStyle,
    };
  
    // Check if observation and location are defined 
    if (!post.observation || !post.observation.location) {
      // Handle the case where observation or location is undefined
      return (
        <div style={indicatorContainerStyle}>
          <ErrorIndicator header="Observation location is not available" body="" />
        </div>
      );
    }
  
    const center = {
      lat: post.observation.location.lat,
      lng: post.observation.location.long,
    };
  
    switch (key.step) {
      // This case block goes and fetches the style of the indicator Container
      // Comments added by Kiernan Pol 10-23-2024
      // Comments updated by
      case "fetching":
        return (
          <div style={indicatorContainerStyle}>
            <CircularProgress />
          </div>
        );

      // This case block displays an error if the map can not be loaded
      // Comments added by Kiernan Pol 10-23-2024
      // Comments updated by
      case "error":
        return (
          <div style={indicatorContainerStyle}>
            <ErrorIndicator
              header="Something went wrong loading the map."
              body=""
            />
          </div>
        );

      // This case block displays the Map and will notify the user to turn on JavaScrip in their web browser.
      // Map will give an error if JavaScript is not turned on in user's web browser.
      // Users must go and allow JavaScript to run on their web browser.
      // If JavaScript is not on it will notify the user to turn it on.
      // This notification can be found in index.html
      // Comments added by Kiernan Pol 10-23-2024
      // Comments updated by
      case "done":
       
        return (

          <LoadScript googleMapsApiKey={key.value}>
            <div>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={8}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                mapTypeId: "terrain",
              }}
              clickableIcons={false}
            >
              <Marker position={center} />
            </GoogleMap>
              {/* <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <h2>Your Location</h2>
              <p>This is where the map is centered.</p>
              </div> */}
            </div>
          </LoadScript>
          
        );
    }
  }
  
  return (
    <main style={{paddingBottom: "5px",}}>
  <div style={{display: 'flex', height: '87vh', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap'}}>
    <div style={{ display: 'flex', height: '87vh', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', height: '87vh', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

        {/* Media (Image/Video) on the left */}
        <div style={{ width: '100%', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '0px'}}>
          <div style={{ width: '100%', maxWidth: '700px', maxHeight: '65vh', overflow: 'hidden', display: 'flex', justifyContent: 'center', marginTop: '25px', alignItems: 'center' }}>
            <MediaLoader post={post} toggleState={toggleState} onMediaTypeDetected={handleMediaTypeDetected}/>
          </div>

          {/* Volcano, Date/Time info: */}
          <div style={{ textAlign: 'center', marginTop: '5px'}}>
            <Link to={`/volcano/${post.volcano.id}`} style={{ fontSize: 20 }}><b>{post.volcano.name}</b></Link>
            <span style={{ fontSize: 20 }}> - </span>
            <span style={{ fontSize: 20 }}>{post.volcano.country} - </span>
            <span style={{ fontSize: 20 }}>
            { post.observation.time_unknown 
                  ? post.observation.datetime.toLocaleString(DateTime.DATE_FULL)
                  : post.observation.datetime.toLocaleString(DateTime.DATETIME_FULL)
                  }</span>

            {/* Mai Nguyen: 12/5/24 added conditional statement for navigation buttons
            hideNavigationButtons must be false to show */}
            <div>
              {!hideNavigationButtons && (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                {/* Button container */}
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                  {/* Erin Fast: added left and right buttons */}
                  {/* Left Button */}
                  <button style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    onClick={handlePrevPost}>  {/* Corrected */}
                    <img src={leftArrow} alt="Left Arrow" style={{ width: "48px", height: "48px" }} />
                  </button>

                  {/* Right Button */}
                  <button style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    onClick={handleNextPost}>  {/* Corrected */}
                    <img src={rightArrow} alt="Right Arrow" style={{ color: "gray", width: "48px", height: "48px" }} />
                  </button>

                </div>
              </div>
              )}
              
            </div>
            {/* Report post button */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
              <Button sx={{ mt: -0.5 }}>
                <Link
                  to={`/reportPost/${post.id}`}
                  underline="none"
                  color="inherit"
                >
                  Report this Post
                </Link>
              </Button>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '5px 0' }}>
              <Restricted role="moderator">
                <Button sx={{ mt: 2 }} onClick={toggleConfirmDeletePost}>
                  Delete this Post
                </Button>
              </Restricted>
            </div>

          </div>
        </div>
      </div>
    </div>

  {/* Information on the right */}
  <div style={{ width: '40%', padding: '10px', marginRight: '0px', marginTop: '30px', flexBasis: 'auto', minWidth: '300px' }}>
    <Container>
      {/* Your Observations content here */}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}><b>Observations:</b></div>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}>Uploaded By:</div>
          <div><Link to={`/profile/${post.uploadedBy}`}>{username}</Link></div>
        </div>
        {checkPending !== "pending" ? undefined : (
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}>Status:</div>
            <div style={{ color: '#000000' }}>{post.status}</div>
          </div>
        )}
        {checkedSights.length === 0 ? undefined : (
          checkedSights.map(([sight, _]) => (
            <div style={{ display: 'flex', marginBottom: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} key={sight}>
              <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}>Sights:</div>
              <div style={{ color: '#000000' }}>{SIGHT_DISPLAY[sight as keyof Sights]}</div>
            </div>
          ))
        )}
        {checkedSounds.length === 0 ? undefined : (
          checkedSounds.map(([sound, _]) => (
            <div style={{ display: 'flex', marginBottom: '10px' }} key={sound}>
              <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}>Sounds:</div>
              <div style={{ color: '#000000' }}>{SOUND_DISPLAY[sound as keyof Sounds]}</div>
            </div>
          ))
        )}
        {checkedSmells.length === 0 ? undefined : (
          checkedSmells.map(([smell, _]) => (
            <div style={{ display: 'flex', marginBottom: '10px' }} key={smell}>
              <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}>Smells:</div>
              <div style={{ color: '#000000' }}>{SMELL_DISPLAY[smell as keyof Smells]}</div>
            </div>
          ))
        )}
      
      </div>
      
      {post.observation.other.additional === "" ? undefined : (
        <Box sx={{ ...obsSectionStyle, alignItems: 'center', marginBottom: '0', padding: '0' }}>
          <h3 style={{ marginRight: '10px', minWidth: '120px', color: '#252627' }}>Additional Notes:</h3>
          <div style={{ color: '#000000' }}>{post.observation.other.additional}</div>
        </Box>
      )}
    </Container>

    {/* Your Weather content here */}
    {post.weather && (
      <Container>
        <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <div style={{ display: 'flex', marginTop: '8px', marginBottom: '10px' }}>
          <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}><b>Weather:</b></div>
        </div>
          {weatherData.map(([label, value]) => (
            <div style={{ display: 'flex', marginBottom: '10px' }} key={label}>
              <div style={{ width: '10vw', minWidth: '120px', color: '#252627' }}>{label}:</div>
              <div style={{ color: '#000000' }}>{value}</div>
            </div>
          ))}
        </div>
      </Container>
    )}

    {/* Toggle switch control -EF */}
    {/* if video, don't show toggle */}
    {/* temp hide toggle */}
    {/* {showToggle && (
      <FormControlLabel
        control={
          <Switch
            checked={toggleState}
            onChange={(event) => setToggleState(event.target.checked)}
            color="primary"
          />
        }
        label="Measure"
        sx={{
          marginLeft: '10px',
          '& .MuiFormControlLabel-label': { fontWeight: 'bold' },
          color: '#252627',
        }}
      />
    )} */}    

    {/* Your Location content here */}
    {post.observation.location && (
      <Container>
        <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <div style={{ width: '100%', height: '100%' }}>
            <VolcanoMap post={post} />
          </div>
        </div>
      </Container>
    )}

  </div>

  <Container>
    {post.status !== "pending" ? undefined : (
      <div>
        <Restricted role="volcanologist">
          <Button
            onClick={() => {
              handleEditObservationsButton();
            }}
          >
            Edit observed sights
          </Button>
        </Restricted>
        <RemoveObservations
          postid={Number(post.id)}
          sights={post.observation.sights}
          open={removeObservationsOpen}
          handleClose={handleRemoveObservationsClose}
          handleUpdate={handleObservationUpdate}
        />
      </div>
    )}
  </Container>
  </div>

  {/* <div style={{ width: '55vw', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '20px', flexBasis: 'auto' }}>
    <Restricted role="moderator">
      <Button sx={{ mt: 2 }} onClick={toggleConfirmDeletePost}>
        Delete this Post
      </Button>
    </Restricted>
  </div> */}

  <Popup
    title="Delete Post"
    open={confirmDeletePost}
    onClose={toggleConfirmDeletePost}
    content={
      <>
        <p>
          Do you want to delete this post?{" "}
          <Button onClick={() => deletePost(post.id)}> Yes </Button>{" "}
          <Button onClick={toggleConfirmDeletePost}> No </Button>
        </p>
      </>
    }
  />
  <Popup
    title="Deletion"
    open={isOpenPost}
    onClose={togglePopupPost}
    content={
      <>
        <p>The selected post is deleted.</p>
      </>
    }
  />
  {overlay}
    </main>
  );
}

// working draggable overlay
// test real convsersion
// drag and length test
function MediaLoader({
  post,
  toggleState,
  onMediaTypeDetected,
}: {
  post: Post;
  toggleState: boolean;
  onMediaTypeDetected: (type: 'image' | 'video') => void;
}) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const imgRef = React.useRef<HTMLImageElement | null>(null);
  const [overlayColor, setOverlayColor] = React.useState<string>('white'); // State for overlay color
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [isEndDragging, setIsEndDragging] = React.useState<boolean>(false);
  const [dragOffset, setDragOffset] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [startPoint, setStartPoint] = React.useState<{ x: number; y: number }>({ x: 150, y: 150 });
  const [endPoint, setEndPoint] = React.useState<{ x: number; y: number }>({ x: 150, y: 400 });
  const [mediaType, setMediaType] = React.useState<'image' | 'video' | 'unknown'>('unknown');
  const [scalingFactor, setScalingFactor] = React.useState<number | null>(null);

  // Known reference for pixel-to-meter conversion
  const referenceRealLength = 5; // 5 meters as the real-world reference
  const referencePixelLength = 100; // 100 pixels span this real-world length

  React.useEffect(() => {
    const testImage = new Image();
    testImage.src = post.observation.photo.toString();

    testImage.onload = () => {
      setMediaType('image');
      onMediaTypeDetected('image');
      // Set scaling factor based on reference
      setScalingFactor(referenceRealLength / referencePixelLength);
    };

    testImage.onerror = () => {
      setMediaType('video');
      onMediaTypeDetected('video');
    };
  }, [post.observation.photo]);

  React.useEffect(() => {
    if (!toggleState) return;

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (canvas && ctx && mediaType === 'image') {
      const img = new Image();
      img.src = post.observation.photo.toString();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        drawOverlay(ctx, startPoint, endPoint, overlayColor);
      };
    }
  }, [toggleState, startPoint, endPoint, post, mediaType, overlayColor]);

  const drawOverlay = (ctx: CanvasRenderingContext2D, start: { x: number; y: number }, end: { x: number; y: number }, color: string) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    const img = new Image();
    img.src = post.observation.photo.toString();

    img.onload = () => {
      ctx.drawImage(img, 0, 0);

      // Draw the vertical line
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      ctx.lineTo(end.x, end.y);
      ctx.strokeStyle = color;
      ctx.lineWidth = 15;
      ctx.stroke();

      // Calculate and display real-life measurement
      const pixelDistance = Math.abs(end.y - start.y);
      if (scalingFactor) {
        const realDistance = pixelDistance * scalingFactor;
        ctx.font = '75px Arial';
        ctx.fillStyle = color;
        ctx.fillText(`${realDistance.toFixed(2)} m`, end.x + 30, (start.y + end.y) / 2);
      }

      // Draw horizontal lines at both ends
      const horizontalLength = 60;

      // Start horizontal line
      ctx.beginPath();
      ctx.moveTo(start.x - horizontalLength / 2, start.y);
      ctx.lineTo(start.x + horizontalLength / 2, start.y);
      ctx.stroke();

      // End horizontal line
      ctx.beginPath();
      ctx.moveTo(end.x - horizontalLength / 2, end.y);
      ctx.lineTo(end.x + horizontalLength / 2, end.y);
      ctx.stroke();
    };
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = canvasRef.current!.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    const startDist = Math.sqrt(
      Math.pow(mouseX - startPoint.x, 2) + Math.pow(mouseY - startPoint.y, 2)
    );
    const endDist = Math.sqrt(
      Math.pow(mouseX - endPoint.x, 2) + Math.pow(mouseY - endPoint.y, 2)
    );

    const threshold = 10;

    if (startDist <= threshold) {
      setIsEndDragging(true);
      setDragOffset({ x: mouseX - startPoint.x, y: mouseY - startPoint.y });
    } else if (endDist <= threshold) {
      setIsEndDragging(true);
      setDragOffset({ x: mouseX - endPoint.x, y: mouseY - endPoint.y });
    } else {
      setIsDragging(true);
      setDragOffset({ x: mouseX - startPoint.x, y: mouseY - startPoint.y });
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDragging && !isEndDragging) return;

    const rect = canvasRef.current!.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    if (isDragging) {
      setStartPoint({ x: mouseX - dragOffset.x, y: mouseY - dragOffset.y });
      setEndPoint({ x: mouseX - dragOffset.x, y: mouseY - dragOffset.y + (endPoint.y - startPoint.y) });
    } else if (isEndDragging) {
      if (dragOffset.x === startPoint.x && dragOffset.y === startPoint.y) {
        setStartPoint({ x: mouseX - dragOffset.x, y: mouseY - dragOffset.y });
      } else {
        setEndPoint({ x: mouseX - dragOffset.x, y: mouseY - dragOffset.y });
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsEndDragging(false);
  };

  const toggleOverlayColor = () => {
    setOverlayColor((prevColor) => (prevColor === 'white' ? 'red' : 'white'));
  };

  const increaseLineLength = () => {
    setEndPoint((prevEndPoint) => ({ ...prevEndPoint, y: prevEndPoint.y + 5 }));
  };

  const decreaseLineLength = () => {
    setEndPoint((prevEndPoint) => ({ ...prevEndPoint, y: prevEndPoint.y - 5 }));
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {mediaType === 'video' ? (
        <video controls style={{ width: '100%', height: '100%' }}>
          <source src={`${String(post.observation.photo)}`} type="video/mp4" />
        </video>
      ) : (
        <canvas
          ref={canvasRef}
          style={{
            display: toggleState ? 'block' : 'none',
            width: '100%',
            height: '100%',
            cursor: isDragging || isEndDragging ? 'grabbing' : 'grab',
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        />
      )}

      {mediaType !== 'video' && (
        <img
          ref={imgRef}
          src={post.observation.photo.toString()}
          alt={`Photograph of ${post.volcano.name}`}
          style={{
            display: toggleState ? 'none' : 'block',
            width: '100%',
            height: '100%',
          }}
        />
      )}

      {/* Controls for adjusting the vertical line */}
      {toggleState && (
      <div
        style={{
          position: 'absolute',
          bottom: 'calc(2% + 15px)', // Positions consistently above the bottom
          right: '5px', // Ensures the buttons stay in the bottom right corner
          zIndex: 1,
          display: 'flex',
          gap: '5px',
          //marginBottom: '15px',
        }}
      >
        <button
            style={{color: 'black', borderColor: 'Primary'}} onClick={toggleOverlayColor}>
            Toggle Color
          </button>
        <button style={{ color: 'black', borderColor: 'Primary' }} onClick={increaseLineLength}>
          Increase Line Length
        </button>
        <button style={{ color: 'black' }} onClick={decreaseLineLength}>
          Decrease Line Length
        </button>
      </div>
    )}
  </div>
  );
}
