import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  SoundsInput,
  SmellsInput,
  SightsInput,
  ObservationCommentInput
} from "../components/ObservationInput";
import { makeRawWipObservation, WipObservation } from "../Observation";
import {
  Button,
  Divider,
  TextField,
} from "@mui/material";
import type { Sights, Smells, Sounds } from "../Observation"; 
import ResizablePopup from "../components/ResizeablePopup";
import { BackButtonHeader } from "../components/BackButtonHeader";
import type { DateTime } from "luxon";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker} from "@mui/x-date-pickers"; //static date picker
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { FileUpload } from "@mui/icons-material";
import "../style/postObservationStyles.css";

/*
DESCRIPTION by Mai Nguyen: The PostObservation.tsx component manages the tagging and submission of user observations 
(like smells, sounds, sights, and additional comments) along with media files (photos and videos).
*/


const PHOTO_BASE_URL = "https://didyouseeitphotos.s3.amazonaws.com/media";
const VIDEO_BASE_URL = "https://didyouseeitphotos.s3.amazonaws.com/video";

export function PostObservation({
  observations,
  currentPhotoIndex,
  totalPhotos,
  onBack,
  onUpdateObservation,
  onNextPhoto,
}: {
  observations: WipObservation[];
  currentPhotoIndex: number; //current photo index
  totalPhotos: number; //total number of photos
  onBack: () => void;
  onUpdateObservation: (updatedObservation: WipObservation) => void;
  onNextPhoto: () => void; //transition to next photo
}) {
  const [observationList] = React.useState<WipObservation[]>(observations);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const [dateTimeOpen, setDateTimeOpen] = React.useState(false);
  const [soundsOpen, setSoundsOpen] = React.useState(false);
  const [smellsOpen, setSmellsOpen] = React.useState(false);
  const [sightsOpen, setSightsOpen] = React.useState(false);
  const [commentsOpen, setCommentsOpen] = React.useState(false);
  const [uploadCompleted, setUploadCompleted] = React.useState(false);
  const [filesUploaded, setFilesUploaded] = React.useState(0);
  const [filesToUpload, setFilesToUpload] = React.useState(0);
  const [missingDate, setMissingDate] = React.useState(false);
  const [currentIndex] = React.useState<number>(0); // initialize so that index out of bounds dont happen, set currentindex was removed
  const [pickedDate, setPickedDate] = React.useState<any>();
  const hasMissingDates = observations.some((observation) => !observation.datetime);

  //Mai Nguyen: update current observation when the user clicks next
  const handleNext = () => {
    if (currentPhotoIndex < totalPhotos - 1) {
      const currentObservation = observationList[currentPhotoIndex]; // Get the current observation
      if (currentObservation) {
        onUpdateObservation(currentObservation); // Save the current observation
        console.log("Updated observations:", observations);
        onNextPhoto(); // Transition to the next photo
      } else {
        console.error("No observation found for the current photo.");
      }
    }
  };
   
  const checkForMissingDate = () => {
    let blankDates = 0;
    for (let i = 0 ; i < observationList.length ; i++){
      if (observationList[i]!.datetime === undefined){
        blankDates++;
      }
    }
    if (blankDates > 0){
      setMissingDate(true);
    }
    else{
      setMissingDate(false);
    }
    console.log('missingDate: ' + missingDate);
  }

  async function uploadObservation(observation: WipObservation) {           //video upload is fixed just need to fix link issue where it needs to end with .mp4 if its a video in database
    
    try {
      let isVideo = false;
      var regexResult = await /^data:(image\/[^;]+|video\/[^;]+);base64,(.+)$/.exec(  // include video file types made into var to allow change for each type
        String(observation.photo)
      );
      if (regexResult) {
        const [, mimeType] = regexResult;
        if (mimeType) {
          isVideo = mimeType.startsWith('video/');    
        }
      }
      
      // {/* this section is having some issues with handling video vs photo it sometimes is null leading a video to go through 
      //  *  the picture upload process making it go through the machine learning photo blurring process this causes an error and does not allow it to be uploaded
      //  *  to the data base but it is uploaded to the s3 bucket 
      //  *  I did some changes that helped out but it still has some errors although now it does not break the entire upload process and only uploads successful videos that
      //  *  have not gone through the photo blurring process
      //  */}
      let photoId;
      if (isVideo) {
        // Handle video upload
        const [_, mime, data] = regexResult!;
        photoId = await fetch("/api/posts/photo/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ video: { mime, data } }),        //video mime,data attempt might actually be services.py that fixes this not sure atm
        }).then((resp) => {
          if (resp.ok) {
            return resp.text();
          } else {
            throw new Error(
              `Error uploading video: ${resp.status} ${resp.statusText}`
            );
          }
        });
      } else {
        // Handle image upload
        const [_, mime, data] = regexResult!;
        photoId = await fetch("/api/posts/photo/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ image: { mime, data } }),
        }).then((resp) => {
          if (resp.ok) {
            return resp.text();
          } else {
            throw new Error(
              `Error uploading photo: ${resp.status} ${resp.statusText}`
            );
          }
        });
      }
      let BASE_URL = isVideo ? VIDEO_BASE_URL : PHOTO_BASE_URL; // Determine base URL based on isVideo
      const photo = new URL(`${BASE_URL}/${photoId}`);

      const body = JSON.stringify(
        makeRawWipObservation({ ...observation, photo })
      );
      const resp = await fetch("/api/posts/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        body,
      });
      if (!resp.ok) {
        throw new Error(
          `Error creating post: ${resp.status} ${resp.statusText}`
        );
      }
    } catch (e: unknown) {
      console.error(e);
      //setSubmitting(false);
    }
  }

  // Functions that toggle the popups on the screen when buttons are pressed.
  // DateTime popup toggle function
  const toggleDateTimePopup = () => {
    setDateTimeOpen(!dateTimeOpen);
  }

  // Sound tag popup toggle function.
  const toggleSoundsPopup = () => {
    setSoundsOpen(!soundsOpen);
  }

  // Smell tag popup toggle function.
  const toggleSmellsPopup = () => {
    setSmellsOpen(!smellsOpen);
  }

  // Sight tag popup toggle function.
  const toggleSightsPopup = () => {
    setSightsOpen(!sightsOpen);
  }

  // Additional observation popup toggle function.
  const toggleCommentsPopup = () => {
    setCommentsOpen(!commentsOpen);
  }

  // Functions used to pass correct values to the popups
  
  // Updates the picked date so that the correct value can be displayed
  const updatePickedDate = (observation: WipObservation) => {
    if (!observation.time_unknown){
      setPickedDate(observation.datetime);
    }
    else{
      if (observation.datetime !== undefined){
        setPickedDate(observation.datetime);
      }
      else{
        setPickedDate(null);
      }
    }
    console.log("Picked date:" + observation);
  }

  // Handles the date change when update button is pressed.
  const handleDateChange = (index: number, newDate: DateTime) => {
    if (observationList[index]!.time_unknown || observationList[index]!.datetime === undefined){
      observationList[index]!.datetime = newDate;
      observationList[index]!.time_unknown = false;
      
    }
    checkForMissingDate();
    toggleDateTimePopup();
  }

  // Handles change in sight tags when update button is pressed.
  const handleSightsChange = (index: number, newSights: Sights) => {
    observationList[index]!.sights = newSights;
    toggleSightsPopup();
  }

  // Handles change in sound tags when update button is pressed.
  const handleSoundsChange = (index: number, newSounds: Sounds, soundComment: string) => {
    observationList[index]!.sounds = newSounds;
    observationList[index]!.other.sounds = soundComment;
    toggleSoundsPopup();
  }

  // Handles change in smell tags when update button is pressed.
  const handleSmellsChange = (index: number, newSmells: Smells, smellComment: string) => {
    observationList[index]!.smells = newSmells;
    observationList[index]!.other.smells = smellComment;
    toggleSmellsPopup();
  }

  // Handles change in additional observations when update button is pressed.
  const handleCommentChange = (index: number, newComment: string) => {
    observationList[index]!.other.additional = newComment;
    toggleCommentsPopup();
  }

  // Handles the file upload when the user submits their observations for upload
  const handleFileUpload = async (observations: WipObservation[]) => {
    setSubmitting(true);
    let uploaded = 0;
    const toUpload = observations.length;
    setFilesToUpload(toUpload);
    
    for (const observation of observations) {
      console.log("Preparing to upload observation: ", observation);
      if (!observation.datetime) {
        console.error("Missing datetime for observation: ", observation);
        continue;
      }
      try {
        await uploadObservation(observation);
        uploaded++;
        console.log(`Uploaded #${uploaded}:`, observation);
        setFilesUploaded(uploaded);
      } catch (error) {
        console.error(`Failed to upload observation #${uploaded}:`, error);
      }
      
    }
    if (uploaded === toUpload) {
      console.log("All files uploaded successfully!");
    } else {
      console.warn("Some files were not uploaded, check logs for details.")
    }
    setUploadCompleted(true);
  };

  // renders a simple upload progress screen  
  if (submitting){
    return(
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
          alignItems: "center"
        }}
      >
        <h1>Uploading Files</h1>
        <h1>{filesUploaded} / {filesToUpload}</h1>
      
        {//Upload Confirmation Popup
            uploadCompleted && <ResizablePopup
            content={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "20px",
                  alignItems: "center"
                }}
              >
                <h1>Thank You!</h1>
                <p>Thank you for your contribution! We will review your post as soon as possible.</p>
                <Button 
                  style={{marginBottom: '10px'}}
                  onClick={ () => {
                    setUploadCompleted(false);
                    navigate("/", { state: { ref: "postSubmitted" } });
                }}
                >
                  OK
                </Button>
              </div>
            }
            handleClose={() => {
              setUploadCompleted(false);
              navigate("/", { state: { ref: "postSubmitted" } });
            }}
          />
        }
      </div>
    );
  }
  
  // Renders the tagging cards for observation input. Updated by Mai Nguyen to render the tagging cards individually for each file
  return(
    <>
      <div className="observation-back-button">
        <BackButtonHeader title={"Tag Uploads"} onBack={onBack}/>
      </div>
      <div className="list-container">
        {observationList[currentPhotoIndex] && (
          <>
            <div>
              <Divider>
                {currentPhotoIndex + 1} / {totalPhotos}
              </Divider>
            </div>
            <div className="card-container">
              <div className="card-photo-bg">
                {String(observationList[currentPhotoIndex]?.photo)
                  .split(":")[1]
                  ?.split("/")[0] === "video" ? (
                  <video
                    controls
                    style={{
                      height: "100%",
                      width: "100%",
                      padding: "2px",
                      contain: "strict",
                    }}
                  >
                    <source
                      src={`${String(
                        observationList[currentPhotoIndex]?.photo
                      )}`}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    className="card-photo"
                    src={String(observationList[currentPhotoIndex]?.photo)}
                    alt="Could not load media :("
                  />
                )}
              </div>
              
            </div>
            <div className="card-button-bg">
                <Button
                  className="card-button"
                  onClick={() => {
                    // setPickedDate(observationList[currentPhotoIndex]?.datetime);
                    // setDateTimeOpen(true);
                    const currentObservation = observationList[currentPhotoIndex];
                    if (currentObservation) {
                      updatePickedDate(currentObservation); // Pass valid observation
                      setDateTimeOpen(true); // Open the popup
                    } else {
                      console.error("Observation is undefined for currentPhotoIndex:", currentPhotoIndex);
                    }
                  }}
                >
                  Date/Time
                </Button>
                <Button
                  className="card-button"
                  onClick={() => {
                    setSightsOpen(true);
                  }}
                >
                  Did You See It?
                </Button>
                <Button
                  className="card-button"
                  onClick={() => {
                    setSoundsOpen(true);
                  }}
                >
                  Did You Hear It?
                </Button>
                <Button
                  className="card-button"
                  onClick={() => {
                    setSmellsOpen(true);
                  }}
                >
                  Did You Smell It?
                </Button>
                <Button
                  className="card-button"
                  onClick={() => {
                    setCommentsOpen(true);
                  }}
                >
                  Additional Observations
                </Button>
              </div>
          </>
        )}
      </div>

        {/* Mai Nguyen: Show "Next" button for all but the last photo */}   
        {currentPhotoIndex < totalPhotos - 1 && (
        <Button
          onClick={handleNext} // Trigger save and transition
        >
          Next
        </Button>
        )}
        {/* Mai Nguyen: Show the error for date time on the last photo, may have to change this later if we are separating pages */}
        {hasMissingDates && currentPhotoIndex === totalPhotos - 1 && (
          <p style={{ color: "red", marginTop: "5px" }}>
            All Files Must Have Date/Time
          </p>
        )}
        <Button 
          sx={{
            width: 200,
          }}
          disabled={hasMissingDates} //Mai Nguyen: disabled if any dates are missing
          startIcon={<FileUpload/>}
          onClick={() => handleFileUpload(observations)} //Mai Nguyen: pass all observations
            style={{ display: currentPhotoIndex === totalPhotos - 1 ? "block" : "none"}}
        >
          Upload {totalPhotos} Files
        </Button>
        
        
        {// Date/time entry popup
          dateTimeOpen && <ResizablePopup
            content={
              <>
                  <div className="popup-content">
                      <h1 className="popup-header">Date/Time</h1>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DateTimePicker
                              renderInput={(props) => <TextField {...props}/>}
                              value={pickedDate}
                              onChange={(newValue) => setPickedDate(newValue)}
                              maxDate={new Date()}
                              label="Date and Time Entry"
                          />
                      </LocalizationProvider>
                      <Button
                        style={{
                          width: '150px',
                          marginBottom: '20px',
                          marginTop: '20px'
                        }}
                        onClick={() => {
                          //Mai Nguyen: update the observation's datetime
                          if (pickedDate) {
                            handleDateChange(currentPhotoIndex, pickedDate);
                          } else {
                            console.error("Picked date is invalid: ", pickedDate);
                          }
                          
                        }}
                      >Update</Button>
                  </div>
              </>
            }
            handleClose={toggleDateTimePopup}           
          />
        }

        {//Sights Popup
          sightsOpen && <ResizablePopup
            content={
              <div>
                <SightsInput
                  initialSights= {observationList[currentIndex]!.sights}
                  onSubmit= {(sights) => {
                    handleSightsChange(currentIndex, sights);
                  }}
                />
              </div>
            }
            handleClose={toggleSightsPopup}
          />
        }

        {//Sounds Popup
          soundsOpen && <ResizablePopup
          content={
            <>
              {console.log("Sounds for #" , currentIndex)}
              <SoundsInput
                initSounds={observationList[currentIndex]!.sounds}
                initialSoundComment={observationList[currentIndex]!.other.sounds}
                buttonLabel="Sounds"
                onSubmit={(sounds, comment) => {
                  handleSoundsChange(currentIndex, sounds, comment);                    
                }}
              />
            </>
          }
          handleClose={toggleSoundsPopup}
          />
        }

        {// Smells Popup
          smellsOpen && <ResizablePopup
          content={
            <>
              {console.log("Smells for #" , currentIndex)}
              <SmellsInput
                initSmells={observationList[currentIndex]!.smells}
                initialSmellComment={observationList[currentIndex]!.other.smells}
                buttonLabel="Smells"
                onSubmit={(smells, comment) => {
                  handleSmellsChange(currentIndex, smells, comment);                    
                }}
              />
            </>
          }
          handleClose={toggleSmellsPopup}
          />
        }

        {// Comments Popup
          commentsOpen && <ResizablePopup
          content={
            <>
              <ObservationCommentInput
                initialComment={observationList[currentIndex]!.other.additional}
                onSubmit={(comment) => {
                  handleCommentChange(currentIndex, comment);
                }}/>
            </>
            }
            handleClose={toggleCommentsPopup}
          />
        }

    </>
  );

}