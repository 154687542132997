/*
* DESCRIPTION: This component handles the instalation of the  Did You See It PWA.
*/
// Discription added by Kiernan Pol

import React from 'react'
import { useEffect, useState } from 'react'
import { Button } from '@mui/material';

const PWAInstaller = ({ childern }: any) => {
    const [installEvent, setInstallEvent] = useState<any>()

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event: any) => {
            event.preventDefault()
            setInstallEvent(event)
        })
    }, [])

    return (
        <>
            {installEvent && (
                <Button
                    sx={{ m: 2 }}
                    onClick={async () => {
                        installEvent.prompt()
                        await installEvent.userChoice
                        setInstallEvent(null)
                    }}
                >
                    Automatically Install Did You See It
                </Button>
            )}
                {childern}
        </>
    )
};

export default PWAInstaller
