import * as React from "react";
import type {Post} from "../Post";
import XLSX from "sheetjs-style";
import {Button} from "@mui/material";
import {DateTime} from "luxon";

export type PostExport = {
    volcano_name: string;
    date?: string;
    time?: string;
    sight_lava_flow: string;
    sight_lava_fountain: string;
    sight_laze: string;
    sight_peles_hair: string;
    sight_slabby_pahoehoe_flow: string;
    sight_volcanic_plume: string;
    sound_burst: boolean,
    sound_explosion: boolean,
    sound_hiss: boolean,
    sound_roar: boolean,
    sound_rumble: boolean,
    sound_shatter: boolean,
    sound_silence: boolean,
    smell_campfire: boolean,
    smell_fireworks: boolean,
    smell_gunpowder: boolean,
    smell_rotten_eggs: boolean,
    smell_nothing: boolean,
    weather_temp?: string,
    weather_wind_speed?: string,
    weather_wind_direction?: string,
    weather_pressure?: string,


}

export function ExportData({postData}: {postData: Post[]}) {
    let formattedPosts: Array<PostExport> = new Array<PostExport>();

    for (let post of postData) {
        const formatted: PostExport = {
            volcano_name: post.volcano.name,
            date: post.observation.datetime?.toLocaleString(),
            time: post.observation.datetime?.toLocaleString(DateTime.TIME_24_SIMPLE),
            sight_lava_flow: post.observation.sights.aALavaFlow,
            sight_lava_fountain: post.observation.sights.lavaFountain,
            sight_laze: post.observation.sights.laze,
            sight_peles_hair: post.observation.sights.pelesHair,
            sight_slabby_pahoehoe_flow: post.observation.sights.slabbyPahoehoeFlow,
            sight_volcanic_plume: post.observation.sights.volcanicPlume,
            sound_burst: post.observation.sounds.burst,
            sound_explosion: post.observation.sounds.explosion,
            sound_hiss: post.observation.sounds.hiss,
            sound_roar: post.observation.sounds.roar,
            sound_rumble: post.observation.sounds.rumble,
            sound_shatter: post.observation.sounds.shatter,
            sound_silence: post.observation.sounds.silence,
            smell_campfire: post.observation.smells.campfire,
            smell_fireworks: post.observation.smells.fireworks,
            smell_gunpowder: post.observation.smells.gunPowder,
            smell_rotten_eggs: post.observation.smells.rottenEggs,
            smell_nothing: post.observation.smells.nothing,
            weather_temp: post.weather?.temperature,
            weather_wind_speed: post.weather?.windSpeed,
            weather_wind_direction: post.weather?.windDirection,
            weather_pressure: post.weather?.pressure,

        }
        formattedPosts.push(formatted);
    }
    const nowTime = new Date();
    const dateTimeFormat = `${nowTime.getFullYear()}-${nowTime.getMonth() + 1}-${nowTime.getDate()}--${nowTime.getHours()}-${nowTime.getMinutes()}`
    console.log(postData[0]);
    async function exportToExcel() {
        const sheet = XLSX.utils.json_to_sheet(formattedPosts);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, "Volcano Data");
        XLSX.writeFile(workbook, `VolcanoData${dateTimeFormat}.xlsx`);
    }

    return <Button style={{marginTop: "10px"}} onClick={exportToExcel}>Export Carousel</Button>
}