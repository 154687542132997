import * as React from "react";
import { AcceptDeny } from "../components/AcceptDeny";
import { CommentInput } from "../components/CommentInput";
import {
  PostGallery,
  PostGalleryState,
  Review,
  ReviewState,
} from "../components/PostGallery";
import { comparePosts, fromRawPost } from "../Post";
import { ErrorPage } from "../components/ErrorPage";
import { LogoIcon } from "../images/LogoIcon";

export function ReviewPosts() {
  const [posts, _] = React.useState(() =>
    fetch("/api/posts/pending", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(async (rawPosts: Array<unknown>) => {
        const posts = await Promise.all(rawPosts.map(fromRawPost));
        posts.sort(comparePosts);
        return posts;
      })
  );

  async function submitReview(
    { posts, page, review }: ReviewState,
    setState: (state: PostGalleryState) => void
  ) {
    const post = posts[page]!.id;
    fetch(`/api/posts/${post}/review`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
      method: "POST",
      body: JSON.stringify(review),
    })
      .then((res) => {
        if (res.ok) {
          const newPosts = Array.from(posts);
          newPosts.splice(page, 1);
          setState({
            step: "list",
            posts: newPosts,
            page: Math.min(page, newPosts.length - 1),
          });
        } else {
          setState({
            step: "error",
            error: `Failed to submit review: ${res.status} ${res.statusText}`,
          });
        }
      })
      .catch((e) => {
        setState({
          step: "error",
          error: `Failed to submit review: ${e}`,
        });
      });
  }

  return (
    <PostGallery
      posts={posts}
      emptyListPlaceholder={
        <ErrorPage
          icon={<LogoIcon style={{ width: "100%", height: "100%" }} />}
          header="There are no posts to review right now."
          body="Check back later."
        />
      }
      galleryTitle="Review posts"
      seeMoreText="Review"
      postViewOverlay={(setState) => (
        <div style={{ textAlign: "center" }}>
          {/* Mai Nguyen: 12/5/24 added approve post text above the acceptdeny buttons */}
          <h2>Approve Post?</h2>
          <AcceptDeny
            setState={(accepted) => {
              const review: Review = {
                status: accepted ? "accepted" : "rejected",
                message: "",
              };
              setState((state) => ({
                ...state,
                step: "finishing-review",
                review,
                submitting: false,
              }));
            }}
          />
        </div>
      )}
      
      reviewHandler={(state, setState) => {
        const [heading, label] =
          state.review.status === "accepted"
            ? ["Post Acceptance", "Accept"]
            : ["Post Rejection", "Reject"];
        return (
          <CommentInput
            onSubmit={(message) => {
              const review = { ...state.review, message };
              const newState = {
                ...state,
                review,
                submitting: true,
              };
              setState(newState);
              submitReview(newState, setState);
            }}
            onBack={() => {
              setState({ ...state, step: "selected" });
            }}
            submitting={state.submitting}
            heading={heading}
            label={label}
          />
        );
      }}
    />
  );
}