import * as React from "react";
import { useNavigate } from "react-router-dom";
import { makeRawWipObservation } from "../Observation";
import type { SightState, WipObservation } from "../Observation";
import Box from "@mui/material/Box";
import { Button, Divider } from "@mui/material";
import { DriveFolderUpload } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import { BackButtonHeader } from "../components/BackButtonHeader";
import { DateTime } from "luxon";
import XLSX, { ColInfo } from "sheetjs-style";
import type { Media } from "../components/MassUpload";
import ResizablePopup from "./ResizeablePopup";

const PHOTO_BASE_URL = "https://didyouseeitphotos.s3.amazonaws.com/media";

// Type used for prefilling the fields of the spreadsheet when the user downloads the template.
export type TemplateExport = {
    file_name: string | undefined,
    volcano_number: number,
    date?: string,
    time?: string,
    sight_lava_flow: string,
    sight_lava_fountain: string,
    sight_laze: string,
    sight_peles_hair: string,
    sight_slabby_pahoehoe_flow: string,
    sight_volcanic_plume: string,
    sound_burst: string,
    sound_explosion: string,
    sound_hiss: string,
    sound_roar: string,
    sound_rumble: string,
    sound_shatter: string,
    sound_silence: string,
    sounds_additional_observations: string,
    smell_campfire: string,
    smell_fireworks: string,
    smell_gunpowder: string,
    smell_rotten_eggs: string,
    smell_nothing: string,
    smells_additional_observations: string,
    additional_observations: string
}

// Main component function
function SpreadSheetObservation({
    media,
    volcanoID,
    onBack,
}:{
    media: Media[];
    volcanoID: number;
    onBack: () => void;
}){
    let templateExports: Array<TemplateExport> = new Array<TemplateExport>();
    let templateImports: Array<TemplateExport> = new Array<TemplateExport>();
    const [submitting, setSubmitting] = React.useState(false);
    const navigate = useNavigate();
    const [observations] = React.useState<WipObservation[]>([]);
    const [filesUploaded, setFilesUploaded] = React.useState(0);
    const [filesToUpload, setFilesToUpload] = React.useState(0);
    const [uploadCompleted, setUploadCompleted] = React.useState(false);

    // Example entry that will be on the first line of the spreadsheet to give user something to reference when filling out spreadsheet
    const exampleEntry: TemplateExport = {
        file_name: 'EXAMPLE_LINE.ex',
        volcano_number: 123456,
        date: 'YYYY-MM-DD',
        time: 'HH:MM:SS',
        sight_lava_flow: "yes",
        sight_lava_fountain: "no",
        sight_laze: "unsure",
        sight_peles_hair: "unsure",
        sight_slabby_pahoehoe_flow: "no",
        sight_volcanic_plume: "yes",
        sound_burst: "yes",
        sound_explosion: "no",
        sound_hiss: "yes",
        sound_roar: "no",
        sound_rumble: "yes",
        sound_shatter: "no",
        sound_silence: "yes",
        sounds_additional_observations: "Add additional sound observations here.",
        smell_campfire: "no",
        smell_fireworks: "yes",
        smell_gunpowder: "no",
        smell_rotten_eggs: "yes",
        smell_nothing: "no",
        smells_additional_observations: "Add additional smells observations here.",
        additional_observations: "Add any other observations here.",
    } 

    // Sets the width of each column in the spreadsheet to improve readability.
    const wsColumnWidths: ColInfo[] = [
        {wch: 30}, // file_name
        {wch: 14}, // volcano-number
        {wch: 14}, // date
        {wch: 14}, // time
        // Sights
        {wch: 17}, // sight_lava_flow
        {wch: 21}, // sight_lava_fountain
        {wch: 12}, // sight_laze
        {wch: 18}, // sight_peles_hair
        {wch: 28}, // sight_slabby_pahoehoe_flow
        {wch: 22}, // sight_volcanic_plume
        // Sounds
        {wch: 13}, // sound_burst
        {wch: 17}, // sound_explosion
        {wch: 12}, // sound_hiss
        {wch: 12}, // sound_roar
        {wch: 14}, // sound_rumble
        {wch: 15}, // sound_shatter
        {wch: 15}, // sound_silence 
        {wch: 35}, // sounds_additional_observations
        // Smells
        {wch: 16}, // smell_campfire
        {wch: 17}, // smell_fireworks
        {wch: 17}, // smell_gunpowder
        {wch: 19}, // smell_rotten_eggs
        {wch: 15}, // smell_nothing
        {wch: 35}, // smells_additional_observations
        // Additional
        {wch: 35}, // additional_observations
    ];

    // Function that uplaods single observation to DYSI
    async function uploadObservation(observation: WipObservation) {
        try {
          const [_, mime, data] = /^data:(image\/[^;]+);base64,(.+)$/.exec(
            String(observation.photo)
          )!;
          const photoId = await fetch("/api/posts/photo/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ image: { mime, data } }),
          }).then((resp) => {
            if (resp.ok) {
              return resp.text();
            } else {
              throw new Error(
                `Error uploading photo: ${resp.status} ${resp.statusText}`
              );
            }
          });
    
          const photo = new URL(`${PHOTO_BASE_URL}/${photoId}`);
    
          const body = JSON.stringify(
            makeRawWipObservation({ ...observation, photo })
          );
          const resp = await fetch("/api/posts/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            body,
          });
    
          if (!resp.ok) {
            throw new Error(
              `Error creating post: ${resp.status} ${resp.statusText}`
            );
          }
    
          navigate("/", { state: { ref: "postSubmitted" } });
        } catch (e: unknown) {
          console.error(e);
        }
    }

    // Function that prepares an array of data that will be used to pre-fill the spreadsheet on template export.
    async function prepareExport(){
        if (media){
            templateExports.push(exampleEntry);
            for (let i = 0; i < media.length ; i++) {
                const formatted: TemplateExport = {
                    file_name: media[i]!.fileName,
                    volcano_number: volcanoID,
                    date: media[i]!.datetime?.toISODate(),
                    time: media[i]!.datetime?.setLocale('fr').toLocaleString(DateTime.TIME_WITH_SECONDS),
                    sight_lava_flow: "yes/no/unsure",
                    sight_lava_fountain: "yes/no/unsure",
                    sight_laze: "yes/no/unsure",
                    sight_peles_hair: "yes/no/unsure",
                    sight_slabby_pahoehoe_flow: "yes/no/unsure",
                    sight_volcanic_plume: "yes/no/unsure",
                    sound_burst: "yes/no",
                    sound_explosion: "yes/no",
                    sound_hiss: "yes/no",
                    sound_roar: "yes/no",
                    sound_rumble: "yes/no",
                    sound_shatter: "yes/no",
                    sound_silence: "yes/no",
                    sounds_additional_observations: "",
                    smell_campfire: "yes/no",
                    smell_fireworks: "yes/no",
                    smell_gunpowder: "yes/no",
                    smell_rotten_eggs: "yes/no",
                    smell_nothing: "yes/no",
                    smells_additional_observations: "",
                    additional_observations: "",
                } 
                templateExports.push(formatted);
            }
        }
    }

    // Handles the creation and download of the spreadsheet that the user will use to tag their observations.
    async function handleTemplateExport() {
        console.log('before prepare');
        await prepareExport();
        console.log('after prepare');
        const nowTime = new Date();
        const dateTimeFormat = `${nowTime.getFullYear()}-${nowTime.getMonth() + 1}-${nowTime.getDate()}`
        var wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(templateExports);
        ws['!cols'] = wsColumnWidths;
        console.log(ws);
        XLSX.utils.book_append_sheet(wb, ws, "Volcano Data");
        console.log(wb);
        XLSX.writeFile(wb, `DYSIupload${dateTimeFormat}.xlsx`, {bookType: "xlsx"});
    }

    // Parses the spreadsheet data upload by the user, associates it with the correct media, and uploads it to the server.
    const handleParseData = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files){
            const reader = new FileReader();
            reader.readAsBinaryString(e.target.files[0]!);
            reader.onload = (e) => {
                const data = e.target!.result;
                const workbook = XLSX.read(data, {type: "binary"});
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName!];
                const parsedData = XLSX.utils.sheet_to_json(sheet!);
                for (let i = 0 ; i < parsedData.length ; i++){
                    let tempData = parsedData[i] as TemplateExport;
                    templateImports.push(tempData); 
                    if (templateImports.length === media.length){
                        console.log('They Match!');
                    }
                    else{
                        console.log("They dont match!");
                    }
                }
                templateImports.shift(); // remove the example entry
                console.log('media.length: ' + media.length);
                console.log('templateImport.length: ' + templateImports.length);
                createObservations();
                console.log('observations.length: ' + observations.length);
                console.log(observations);

                if (media.length === templateImports.length && media.length === observations.length){
                    handleFileUpload(observations);
                }
                
            }
        }
    }

    // Handles the uplaod of all the files by calling the uploadObservation function multiple times.
    const handleFileUpload = async (observationList: WipObservation[]) => {
        setSubmitting(true);
        let uploaded = 0;
        const toUpload = observationList.length;
        setFilesToUpload(toUpload);
        for (let i = 0 ; i < observationList.length ; i++){
          console.log('uploading #' + i);
          await uploadObservation(observationList[i]!);
          uploaded++;
          setFilesUploaded(uploaded);
        }
        setUploadCompleted(true);
    }
    
    // Converts yes/no string to true/false boolean
    // NOTE: the .toLowerCase() function seems to be causing errors on certain browsers and canceling upload process.
    // This has not been repeatable as of yet. Some browsers (Chrome) do not have an issue where others (Safari) do.
    function stringToBoolean (observationTag: string): boolean {
        observationTag.toLowerCase();
        if (observationTag === "yes"){
            return true;
        } else{
            return false;
        }
    }

    // Creates observation objects from parsed spreadsheet data and adds them to an array to be uploaded.
    function createObservations() {
        console.log('in createObservations');
        if (media.length === templateImports.length){
            for (let i = 0 ; i < media.length ; i++){
                let tempObservation: WipObservation = {
                    photo: media[i]?.mediaFile!,
                    location: media[i]?.location!,
                    volcano: volcanoID,
                    datetime: DateTime.fromISO(`${templateImports[i]?.date!}T${templateImports[i]?.time}`),
                    time_unknown: templateImports[i]?.time === '' ? true : false,
                    sounds: { 
                        rumble: stringToBoolean(templateImports[i]?.sound_rumble!),
                        roar: stringToBoolean(templateImports[i]?.sound_roar!),
                        shatter: stringToBoolean(templateImports[i]?.sound_shatter!),
                        hiss: stringToBoolean(templateImports[i]?.sound_hiss!),
                        explosion: stringToBoolean(templateImports[i]?.sound_explosion!),
                        burst: stringToBoolean(templateImports[i]?.sound_burst!),
                        silence: stringToBoolean(templateImports[i]?.sound_silence!),
                        skip: false
                    },
                    smells: {
                        rottenEggs: stringToBoolean(templateImports[i]?.smell_rotten_eggs!),
                        gunPowder: stringToBoolean(templateImports[i]?.smell_gunpowder!),
                        fireworks: stringToBoolean(templateImports[i]?.smell_fireworks!),
                        campfire: stringToBoolean(templateImports[i]?.smell_campfire!),
                        nothing: stringToBoolean(templateImports[i]?.smell_nothing!),
                        skip: false,
                    },
                    sights: {
                        lavaFountain: templateImports[i]?.sight_lava_fountain! as SightState,
                        aALavaFlow: templateImports[i]?.sight_lava_fountain! as SightState,
                        slabbyPahoehoeFlow: templateImports[i]?.sight_lava_fountain! as SightState,
                        pelesHair: templateImports[i]?.sight_lava_fountain! as SightState,
                        laze: templateImports[i]?.sight_lava_fountain! as SightState,
                        volcanicPlume: templateImports[i]?.sight_lava_fountain! as SightState,
                    },
                    other: {
                        sounds: templateImports[i]?.sounds_additional_observations!,
                        smells: templateImports[i]?.smells_additional_observations!,
                        additional: templateImports[i]?.additional_observations!,
                    },
                }
                observations?.push(tempObservation);
            }

        }
        else{
            console.log("Array length does not match");
            console.log('templateImport.length: ' + templateImports.length);
            console.log('media.length: ' + media.length);
        }
    }

    // Displays simple upload screen
    if (submitting){
        return(
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems:"center",
              rowGap: "10px"
            }}
          >
            <h1>Uploading Files</h1>
            <h1>{filesUploaded} / {filesToUpload}</h1>
            {//Upload Confirmation Popup
            uploadCompleted && <ResizablePopup
                content={
                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                    alignItems: "center"
                }}
                >
                    <h1>Thank You!</h1>
                    <p>Thank you for your contribution! We will review your post as soon as possible.</p>
                    <Button onClick={ () => {
                    setUploadCompleted(false);
                    navigate("/", { state: { ref: "postSubmitted" } });
                    }}
                    >
                    OK
                    </Button>
                </div>
                }
                handleClose={() => {
                setUploadCompleted(false);
                navigate("/", { state: { ref: "postSubmitted" } });
                }}
            />
            }
          </div>
        );
    }
    
    // Displays the buttons used for export and upload.
    return(
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    justifyContent: "center",
                }}
            >
                <BackButtonHeader title={"Spreadsheet Upload"} onBack={onBack}/>
                <div>
                    <Button
                        sx={{width: '100%'}}
                        onClick={handleTemplateExport}
                        startIcon= {<DownloadIcon/>}
                    >
                        Download Template
                    </Button>
                </div>
                <Box sx={{ padding: "20px 0" }}>
                    <Divider></Divider>
                </Box>
                <div>
                    <Button
                        sx={{width: '100%'}} 
                        component="label" 
                        startIcon={<DriveFolderUpload />}
                    >
                        Upload Data
                        <input type="file" 
                            multiple = {false}
                            onChange={(e) => handleParseData(e)}
                            style={{ display: "none"}}
                        />
                    </Button>
                </div>
            </div>
        </>
    );
}
export default SpreadSheetObservation;