import React, {Component, useEffect, useRef, useState} from "react";
import type {Post} from "../Post";
import "swiper/css";
import "swiper/css/effect-coverflow";
import {Autoplay, FreeMode, EffectCoverflow, Navigation, Thumbs} from "swiper";
import {ErrorIndicator} from "./ErrorPage";
import {LogoIcon} from "../images/LogoIcon";
import {StartIcon} from "../images/StartIcon"
import {StopIcon} from "../images/StopIcon";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import {makeRawFullPost} from "../Post";
import Button from "@mui/material/Button";

import rightArrow from "../images/arrow-right-circle(new).png";
import leftArrow from "../images/arrow-left-circle(new).png";

export interface CarouselProps {
    posts: Post[];
}

export interface CarouselState {
    data: Post[];
}

// Media Carousel handles the 'full' carousel in which multiple images are shown at once
// and the thumbnail navigation present above the primary images
// Class instance initialized with an array of Posts, re-render should occur when state changes

/* NOTE:
 * When making code changes locally, the page may require a refresh to fix indefinite loading of images
 * This shouldn't cause issues with the live server
 */

//////////////////////////////////////////////////////////////////////////////////////////////////
export class MediaCarousel extends Component<CarouselProps, CarouselState> {
    constructor(props: CarouselProps) {
        super(props);
        this.state = {
            data: new Array<Post>
        }
    }

    override componentDidUpdate(prevProps: Readonly<CarouselProps>) {
        if (this.props.posts !== prevProps.posts) {
            this.setState({
                data: this.props.posts
            })
        }
    }

    override render() {
        const {data} = this.state;

        // Catch errors and volcanoes with no posts
        if (data.length === 0) {
            return <ErrorIndicator
                        icon={
                            <LogoIcon
                                className="logo"
                                style={{
                                width: "100%",
                                height: "100%",
                                }}
                            />
                        }
                        header={"We couldn't find any posts here."}
                    />
        }

        return (
            <MediaSwiper
                posts={data}
            />
        )
    }
}
//////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////
interface SwiperProps {
    posts: Post[]
}
//////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////
// MediaSwiper function needed to be separated to utilize React useEffect
// The carousel main function
function MediaSwiper(this: any, props: SwiperProps) {
    const swiperRef = useRef<SwiperRef>(null);
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    const slidesToShow = Math.min(10, props.posts.length);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isRunning,setIsRunning] = useState(true);
    //Set the autoplay speed and the transition speed
    const autoplaySpeed = 5000;
    const transitionSpeed = 800;
    const [username, setUsername] = React.useState("");

    useEffect(() => {
        const swiper = swiperRef.current?.swiper;
        if (swiper) {
            const handleSlideChange = () => {
                setCurrentIndex(swiper.activeIndex);
                const userId = props.posts[swiper.activeIndex]!.uploadedBy; // Get the user ID from the current post
                
                // Fetch the username based on userId
                fetch(`/api/users/public/${userId}/`)
                    .then((res) => res.json())
                    .then((json) => {
                        setUsername(json.username); // Set the username
                    })
                    .catch((error) => {
                        console.error("Error fetching username:", error);
                    });
            };

            swiper.on('slideChange', handleSlideChange)

            // Initial fetch for the first post's username
            const initialUserId = props.posts[0]!.uploadedBy;
            fetch(`/api/users/public/${initialUserId}/`)
                .then((res) => res.json())
                .then((json) => {
                    setUsername(json.username);
                })
                .catch((error) => {
                    console.error("Error fetching initial username:", error);
                });

            return () => {
                swiper.off('slideChange', handleSlideChange);
            };
        }
        return () => {};
    }, [props.posts])
    {/*Button  boolean conditions*/}
    const handleStart = ()=>{
        setIsRunning(true);
        //Activate carousel
        swiperRef.current?.swiper?.autoplay?.start();
    };

    const handleStop = () => {
        setIsRunning(false);
        //Deactivate carousel
        swiperRef.current?.swiper?.autoplay?.stop();
    };
    
    const handleNext = () => {
        const swiper = swiperRef.current?.swiper;
        if (!swiper) return;
    
        if (currentIndex + 1 < props.posts.length) {
          swiper.slideNext();
        } else {
          // Reset to the first slide if at the last slide
          swiper.slideTo(0);
          setCurrentIndex(0);
        }
      };
    
      const handlePrev = () => {
        const swiper = swiperRef.current?.swiper;
        if (!swiper) return;
    
        if (currentIndex - 1 >= 0) {
          swiper.slidePrev();
        } else {
          // Reset to the last slide if at the first slide
          swiper.slideTo(props.posts.length - 1);
          setCurrentIndex(props.posts.length - 1);
        }
      };

    return (
        <div style={{width: "90vw",         // Make the container a bit narrower than the full viewport
            padding: "0 5vw",      // Add padding on the sides for white space
            boxSizing: "border-box", // Ensure padding doesn't affect total width
            position: "relative"
            }}>
            <div style = {{
                display:'flex', 
                justifyContent: 'flex-start', 
                alignItems:'center',
                position: 'relative',
                paddingLeft:'15vw', 
                marginTop: '1vh',
                marginBottom: '1vh'
                }}> 
                {/*Display start and stop on top of carousel*/}  
                <button
                    onClick={handleStart}
                    disabled={isRunning}
                    style = {{background: 'none', border: 'none', cursor: isRunning ? 'not-allowed': 'pointer'}}>
                    {/*icons colors switch from black to gray depending on selection*/}
                    <StartIcon style = {{color: isRunning ? "darkred":"black",width:"48px", height:"48px"}}/>
                </button>  

                <button
                    onClick = {handleStop}
                    disabled =  {!isRunning}
                    style = {{background: 'none', border: 'none', cursor: isRunning ? 'not-allowed': 'pointer', marginLeft: '5px'}}>
                    <StopIcon style = {{color:  !isRunning ? "darkred":"black", width: "48px", height:"48px"}}/>
                </button>
            </div>

            {/* Left Arrow Button */}
            <button
                onClick={handlePrev}
                className="carousel-arrow left-arrow"
            >
                <img src={leftArrow} alt="Left Arrow" style={{ width: '48px', height: '48px' }} />
            </button>

            {/* Right Arrow Button */}
            <button
                onClick={handleNext}
                className="carousel-arrow right-arrow"
            >
                <img src={rightArrow} alt="Right Arrow" style={{ width: '48px', height: '48px' }} />
            </button>

            {/*Display the Carousel next*/}
            <Swiper
                ref={swiperRef}
                //loop={true} // Enable loopping on the carousel
                navigation={false}
                effect={"coverflow"}
                thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                modules={[Autoplay, Navigation, EffectCoverflow, Thumbs]}
                autoplay = {isRunning?{delay:autoplaySpeed}:false}
                speed = {transitionSpeed}
                slidesPerView={1.5}
                centeredSlides={true}
                coverflowEffect={{
                    rotate: 0,
                    modifier: 1,
                    scale: 0.9,
                    depth: 100,
                    stretch: window.innerWidth/2.5,
                }}
                className="mainSwiper"
                style={{
                    maxWidth: "100vw",
                }}

            >
                {props.posts.map((post: Post, index: number) => (
                    <SwiperSlide
                        key={index}
                        style={{
                            overflow: "hidden",
                            display: "flex",
                            objectFit: "contain",
                            height: "35vw",
                        }}>
                            
                        <div className="post-details2">
                            {/* Media Container */}
                            <div className="media-container" style={{justifyContent: "center"}}>
                                <MediaLoader post={post} />
                            </div>
                            <h2>Volcano Name: {post.volcano.name}</h2>
                            <p>Date: {post.observation.datetime.toLocaleString({ dateStyle: "long" })}</p>
                            <p>Uploaded By: {username}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {/*Display the Post button, the has a link to the current post in the carousel*/}
            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <PostButton
                    post={props.posts[currentIndex]}
                />
            </div>
            {/*Display Thumbnail scroll*/}
            <Swiper
                onSwiper={setThumbsSwiper}
                modules={[FreeMode, Navigation, Thumbs]}
                freeMode={true}
                watchSlidesProgress={true}
                slidesPerView={slidesToShow}
                spaceBetween={1}
                style={{
                    width: `calc(6% * ${slidesToShow}`,
                    paddingTop: "5vh",
                    paddingBottom: "0px",
                }}
            >
                {props.posts.map((post: Post, index: number) => (
                    <SwiperSlide
                        key={index}
                        style={{
                            overflow: "hidden",
                            display: "flex",
                            height: "3vw",
                            backgroundColor: "transparent",
                        }}>
                        <MediaLoaderTOPBAR post={post} />
                        <div className="swiper-lazy-preloader"/>
                    </SwiperSlide>
                ))}
            </Swiper>


        </div>
    )
}
//////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////
// Returns a button with a link to the currently active post/image
function PostButton(props: any) {
    return <Link
        to={`/post/${props.post.id}`}
        state={{ viewingPost: makeRawFullPost(props.post) }}
        style={{ color: "darkred", marginTop: "2vh" }}
    >
        <Button type="button">
            View Post
        </Button>
    </Link>
}
//////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////
function MediaLoader({ post }: { post: Post }) {      //attempt to load image if broken load as a video (future: change way the link is created on the database and add video to the end of the link and parse it or make a separate folder for videos)
    const [mediaType, setMediaType] = React.useState<'image' | 'video' | 'unknown'>('unknown');     //might be a hard task if there are a lot of videos since database will need to be changed. Unless videos are placed in a different folder in s3 bucket but database will still need to be updated for all video links

    React.useEffect(() => {
        const testImage = new Image();
        testImage.src = post.observation.photo.toString();
        testImage.onload = () => {
            setMediaType('image');
        };
        testImage.onerror = () => {
            setMediaType('video');
        };
    }, [post.observation.photo]);

    return (
    <>
        {mediaType === 'video' ? (            //display video if media is video Changed to match Garretts image display
                                            <video controls style={{ width: '100%', height: '100%', display:"flex", margin:"auto", background: 'rgba(85, 85, 85, 0.90)' } }>
                                                <source src={`${String(post.observation.photo)}`} type='video/mp4' />
                                            </video>
                                   ) : 
                    (   <img
                        src={post.observation.photo.toString()}
                        style={{
                            display: "flex",
                            objectFit: "fill",
                            height:"100%",
                            width:"100%",
                            margin: "auto",
                        }}
                    />
                                   )}
    </>
    );
}
//////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////
function MediaLoaderTOPBAR({ post }: { post: Post }) {    //attempt to load image if broken load as a video (future: change way the link is created on the database and add video to the end of the link and parse it or make a separate folder for videos)
    const [mediaType, setMediaType] = React.useState<'image' | 'video' | 'unknown'>('unknown');     //might be a hard task if there are a lot of videos since database will need to be changed. Unless videos are placed in a different folder in s3 bucket but database will still need to be updated for all video links

    React.useEffect(() => {
        const testImage = new Image();
        testImage.src = post.observation.photo.toString();
        testImage.onload = () => {
            setMediaType('image');
        };
        testImage.onerror = () => {
            setMediaType('video');
        };
    }, [post.observation.photo]);

    return (
    <>
        {mediaType === 'video' ? (            //display video for top bar and has auto play when mouse is over icon Also removed controls on video to allow icon viewing of video on topbar
                                               //Changed the width of video to 100% previously to 90%
                                               <video muted style={{ width: '100%', height: '100%', display:"flex", objectFit:'fill',margin:"auto", backgroundColor: "transparent",} }
                                               onMouseOver={(event) => event.currentTarget.play()} 
                                               onMouseOut={(event) => {
                                                   event.currentTarget.pause();
                                                   //event.currentTarget.currentTime = 0;
                                               }}
                                           >
                                               <source src={`${String(post.observation.photo)}`} type="video/mp4"></source>
                                           </video>
                                   ) : 
                    (   <img
                        src={post.observation.photo.toString()}
                        style={{
                            display: "flex",
                            objectFit: "fill",
                            height:"100%",
                            width:"100%",
                            margin: "auto",
                        }}
                    />
                                   )}
    </>
    );
}
//////////////////////////////////////////////////////////////////////////////////////////////////