/*
* DESCRIPTION: This component handles the user registration process.
*/
// Discription added by Kiernan Pol 11-13-2024
// Discription updated by 
import * as React from 'react';
import AccountInfoInput from '../components/AccountInfoInput'
import {defaultUserInfo, UserInfo} from '../UserInfo'
import {useState} from 'react';
import Button from '@mui/material/Button';
import EULApopover from '../components/EULApopover';
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';


////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Register = () => {

  function isEmailValid(email: string) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  }
  const isMobile = useMediaQuery('(max-width: 600px)');

  let stripCharacters = (number: string) => {
    return number.replace(/\D/g, "");
  }
  

  const handleSubmit = (userInfo : UserInfo) => {
    setMessage([""])
    if(userInfo.username === '' || userInfo.email === '' || userInfo.password === ''
    || userInfo.re_password === ''){
      setMessage((message) => [...message, "Please fill in all required fields. "])
    } else {
      registerUser();
    }

    // This code block checks to see if the user entered a valid email
    const email = userInfo.email;
    if(!isEmailValid(email)) {
      setMessage((message) => [...message, "Please enter a valid email address."]);
    }
  };



  
  
  let registerUser = async () => {
    userInfo.phone = stripCharacters(userInfo.phone);
    fetch(`/auth/users/`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body : JSON.stringify({email : userInfo.email, username : userInfo.username, password: userInfo.password, re_password : userInfo.re_password})
    })
    .then((response) => {
      if(response.ok){
        setDialogHeading("Success")
        setMessage((message) => [...message, "Registration successful. Please proceed to your email to complete activation."]);
        fetch(`/api/users/phoneSetup/`, {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({email : userInfo.email, phone: userInfo.phone, country_code: userInfo.country_code, preferred_contact: userInfo.preferred_contact})
        }).then((response) => {
          if(response.ok){
            if(!isMobile){
              window.open(`${window.location.origin}/success?email=${userInfo.email}`);
            }
            return response.json();
          }
          else{
            throw response
          }
        })
        console.log(userInfo);
        return response.json();
      }
      else{
        if (response.status === 400){
          response.json().then((data) => {
            Object.keys(data).forEach((element) => {
              setDialogHeading("Registration Failed");
              setMessage((message) => [...message, data[element]]);
              setOpenDialog(true);
            })
          })
        }else if(response.status === 500){
          setDialogHeading("Registration Failed");
          setMessage((message) => [...message, "Something went wrong. Please try again later."]);
          setOpenDialog(true);

        }

        throw response;

      }
     }).catch((error) => {
      console.log(error)
    })
    
  }
  
  const [userInfo, setUserInfo] = useState(defaultUserInfo())
  const [message, setMessage] = useState([""])
  const [dialogHeading, setDialogHeading] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  
  

const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogHeading('');
    setMessage([""]);
}

  return (
    <div>
      <div>
        <div
        style = {{
          textAlign: 'center'
        }}>
          <h1>Register Here:</h1>
          <h5>*Required Fields</h5>
          <div>
          <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle>
                        {dialogHeading}
                      </DialogTitle>
                    <DialogContent>
                      {message.join(' ')}
                    </DialogContent>
          </Dialog>

          </div>
            
        </div>
        <div>
          <AccountInfoInput userInfo = {userInfo} setUserInfo = {setUserInfo}/>
        </div>
        <div style = {{display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center', marginLeft: isMobile ? 30 : 160}}>
            <h5>By clicking Register, you agree to our</h5>
            <EULApopover/>

        </div>

          <Button
               type="submit"
               onClick={() => handleSubmit(userInfo)}
               fullWidth
               sx={{ mt: 3, mb: 2 }}
             >Register</Button>
      </div>

    </div>
  );
};

export default Register;
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////