/* 
DESCRIPTION: this is the homepage of Did You See It
Updated By: Mai Nguyen - homepage tiles, Kiernan Pol - map
*/

import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import type {Post} from "../Post";
import {
  Fab,
  Tooltip,
} from "@mui/material";
import {fromFullRawPost} from "../Post";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../style/styles.css";
import "../style/logo.css";
import {ThemeProvider} from "@mui/material";
import {theme} from "../pages/About";
import VolcanoMap from '../pages/Search';

//////////////////////////////////////////////////////////////////////////////////////////////////
/* This page needs CSS for mobile and desktop change font to match header on red headers and text to match about page*/
export default function Home() {
  const [recentPosts, setRecentPosts] = React.useState<Post[]>(new Array<Post>());
  const [loading, setLoading] = React.useState<boolean>(true);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const headers: HeadersInit = {
      "Content-Type": "application/json",
    };
  
    // Add Authorization header only if token exists using bracket notation
    if (token) {
      headers["Authorization"] = `JWT ${token}`;
    }
  
    fetch("/api/posts/recent-posts/", {
      method: "GET",
      headers: headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("API request failed");
        }
        return res.json();
      })
      .then((raw: unknown[]) => {
        console.log("API Response:", raw); // Log the raw response for debugging
        return Promise.all(raw.map(fromFullRawPost));
      })
      .then(setRecentPosts)
      .catch((err) => {
        console.error("Error fetching recent posts:", err);
      })
      .finally(() => setLoading(false));
  }, []);  
  const scrollToInstallSection = () => {
    navigate("/about");
    setTimeout(() => {
      const installSection = document.getElementById("install");
      if (installSection) {
        const elementTop = installSection.getBoundingClientRect().top + window.pageYOffset;
        const offset = window.innerHeight / 2 - installSection.offsetHeight / 2 - 100; //the last number controls where the offset is.
        window.scrollTo({
          top: elementTop - offset,
          behavior: "smooth",
        });
      }
    }, 100); // there is a delay to ensure page navigation is completed
  };
  return (
    <ThemeProvider theme={theme}>
      <main>
        <h2 className="title-recent-posts">Recent Posts</h2>
        {loading ? (
          <div className="recent-post-loading-message">
            Loading recent posts...
            <div className="loading-spinner"></div>
          </div>
        ) : recentPosts.length > 0 ? (
          <div className="post-grid">
            {recentPosts.map((post) => (
              <FeaturedPostTile key={post.id} post={post} />
            ))}
          </div>
        ) : (
          <p>No recent posts available</p>
        )}

      <VolcanoMap />

      {isMobile ? (
        <Tooltip title="Install as an app">
          <Fab
            style={{
              position: "fixed",
              bottom: "5%",
              right: "5%",
              color: "white",
              backgroundColor: "darkred",
            }}
            onClick={scrollToInstallSection}
          >
            <InstallMobileIcon />
          </Fab>
        </Tooltip>
      ): (
          <Tooltip title="Install as an app">
            <Fab
              style={{
                position: "fixed",
                right: "5%",
                bottom: "5%",
                minWidth: "80px",
                minHeight: "80px",
                maxWidth: "100px",
                maxHeight: "100px",
                color: "white",
                backgroundColor: "darkred",
              }}
              onClick={scrollToInstallSection}
            >
              <InstallMobileIcon style={{ width: "45%", height: "45%" }} />
            </Fab>
          </Tooltip>
        )}
      </main>
 </ThemeProvider>
 
);
}
//////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////
function FeaturedPostTile({post }: {post: Post}){
  const [mediaType, setMediaType] =  React.useState<'image' | 'video' | 'unknown'>('unknown');
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  React.useEffect(() => {
    const testImage = new Image();
    testImage.src = post.observation.photo.toString();
    testImage.onload = () => setMediaType('image');
    testImage.onerror = () => setMediaType('video');
  }, [post.observation.photo]);

  //URL for volcano page
  const volcanoPageUrl= `/volcano/${post.volcano.id}`;

  // Function to handle play/pause toggle for video on mobile
  const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    const video = event.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  return (
    <div className="featured-post-tile">
      {mediaType === 'image' ? (
        <Link to={volcanoPageUrl}>
          <img src={post.observation.photo.toString()} className="media-content" alt="Post Media" />
        </Link>
      ) : (
        <Link to={volcanoPageUrl}>
          <video
            controls={!isMobile}
            muted={!isMobile}
            autoPlay={!isMobile}
            className="media-content"
            onClick={isMobile ? handleVideoClick : undefined} // Toggle play/pause on click for mobile
          >
            <source src={post.observation.photo.toString()} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Link>
      )}
      <div className="post-details">
        <Link to={volcanoPageUrl}>
          <h2>{post.volcano.name}</h2>
        </Link>
        <p>{post.observation.datetime.toLocaleString({ dateStyle: "long" })}</p>
      </div>
    </div>
  );
}
//////////////////////////////////////////////////////////////////////////////////////////////////