import {IconButton, TextField} from '@mui/material';
import * as React from 'react'
import type {UserInfo}  from "../UserInfo"
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PhoneEntry from './PhoneEntry';



function AccountInfoInput ({userInfo, setUserInfo}:
  {userInfo: UserInfo; setUserInfo: (info: UserInfo) => void;}
  ) {

    const [showPassword, setShowPassword] = useState(false);
    const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div>
      <div style = {{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: isMobile ? 350 : 600}}>
          <TextField
            style={{
              margin: '8px'
            }}
            required
            label="Username"
            onChange={(e) => {
              setUserInfo({...userInfo, username: e.target.value});
            }}
            ></TextField>
        
          <TextField
          style={{
            margin: '8px'
          }}
          required
          label="Email"
          onChange={(e) => {
            setUserInfo({...userInfo, email : e.target.value.toLowerCase()});
          }}
          ></TextField>

          <TextField
          style={{
            margin: '8px'
          }}
          required
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(prev => !prev)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          // value={userInfo.password}
          onChange={(e) => {
            setUserInfo({...userInfo, password : e.target.value});
          }}
          ></TextField>

          <TextField
          style={{
            margin: '8px'
          }}
          required
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(prev => !prev)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          // value={userInfo.checkpass}
          onChange={(e) => {
            setUserInfo({...userInfo, re_password : e.target.value});
          }}
          ></TextField>
          
        </div>

        <div>

          
          <PhoneEntry isMobile = {isMobile} userInfo = {userInfo} setUserInfo = {setUserInfo}/>
          

        </div>
        
        <div>
          
        </div>

    </div>
    
        
  )
    
}

export default AccountInfoInput